import React, { useEffect, useState } from 'react';
import { fs } from '../../firebase'; // Import Firestore
import { getAuth } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';

const OrderHistory = () => {
    const [orders, setOrders] = useState([]);
    const auth = getAuth(); // Get auth instance
    const user = auth.currentUser; // Get current user

    useEffect(() => {
        if (!user) {
            return; // If there is no authenticated user, don't fetch orders
        }

        // Create a query to the DeliveryOrders collection, filter by userId
        const fetchOrders = async () => {
            try {
                const ordersQuery = query(
                    collection(fs, "DeliveryOrder"),
                    where("userId", "==", user.uid) // Filter by userId
                );
                const querySnapshot = await getDocs(ordersQuery);
                const fetchedOrders = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setOrders(fetchedOrders); // Save orders data to state
            } catch (error) {
                console.error("Error loading orders:", error);
            }
        };

        fetchOrders();
    }, [user]);

    return (
        <div>
            <h2>Order History</h2>
            {orders.length === 0 ? (
                <p>No orders to display</p>
            ) : (
                orders.map(order => (
                    <div key={order.id} style={styles.card}>
                        <h3>Order #{order.id}</h3>
                        <p><strong>order receipt method: </strong> {order.orderReceipt}</p>
                        <p><strong>Delivery Address:</strong> {order.destination.address}</p>
                        <p><strong>Products:</strong></p>
                        <ul>
                            {order.products.map((product, index) => (
                                <li key={index}>
                                    {product.name} - {product.quantity} шт. - {product.price}₾
                                </li>
                            ))}
                        </ul>
                        <p><strong>Total Price:</strong> {order.totalPrice}₾</p>
                        <p><strong>Status:</strong> {order.status}</p>
                    </div>
                ))
            )}
        </div>
    );
};

// Minimalist styles
const styles = {
    card: {
        border: '1px solid #ddd',
        padding: '16px',
        margin: '8px 0',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
    },
};

export default OrderHistory;