import React, {useState, useEffect, useRef} from "react";
import {fs, authentication, storage} from "../firebase";
import {doc, setDoc, getDoc, query, collection, where, getDocs, updateDoc} from "firebase/firestore";
import {GoogleAuthProvider,signOut, signInWithPopup, signInWithEmailAndPassword, getAuth, signInWithPhoneNumber,setPersistence,browserLocalPersistence, } from "firebase/auth";
import '../css/index.css';

import {ref, uploadBytes, getDownloadURL} from "firebase/storage";
import '../css/userpage.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Bar from "../elements/bar";
import Registration from "./registartion";
function UserPage({handleXBarItemChange}) {
    const {t} = useTranslation();
    const fileInputRef = useRef(null);
    const googleProvider = new GoogleAuthProvider();

    // Form state
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [lOGstatus, setLOGstatus] = useState(localStorage.getItem('LOGstatus'));
    const [postI, setPostI] = useState(localStorage.getItem('USER_POST'));
    const [nameI, setNameI] = useState(localStorage.getItem('USER_NAME'));
    const [loginI, setLoginI] = useState(localStorage.getItem('USER_LOGIN'));
    const [profileImage, setProfileImage] = useState(localStorage.getItem('USER_PROFILE_IMAGE') || '');
    const [Status, setStatus] = useState(localStorage.getItem('USER_STATUS'));
    const [name, setName] = useState('');
    const [post, setPost] = useState('');
    const [login, setLogin] = useState('');
    const [number, setNumber] = useState('');
    const [age, setAge] = useState('');
    const [cash, setCash] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [addModal, setAddModal] = useState(false);
    const [addModalStatus, setAddModalStatus] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isPhysical, setIsPhysical] = useState(true);
    const [disableButton, setDisableButton] = useState(localStorage.getItem('PARTNER_STATUS'));

    // Registration form state
    const [showReg, setShowReg] = useState(false);
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [verifyERROR, setVerifyERROR] = useState('');

    // Verification state
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

    // Coins state
    const [coins, setCoins] = useState([0, 0, 0, 0, 0]);
    const [previousTotalCoins2, setPreviousTotalCoins2] = useState(0);


    const handleBack = () => {
        setShowReg(false); // Hide the Registration component when "Назад" is clicked
    };
    // Google Login Handler
    const handleGoogleLogin = async () => {
        try {
            await setPersistence(auth, browserLocalPersistence); // Сохраняем сессию

            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            await handleAuthResult(user);
        } catch (error) {
            console.error("Google login error:", error);
            alert("Login failed: " + error.message);
        }
    };

// Обработка результата аутентификации
    const handleAuthResult = async (user) => {
        try {
            const userDocRef = doc(fs, 'Users', user.email);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();

                // Сохраняем данные в localStorage
                localStorage.setItem('LOGstatus', 'true');
                localStorage.setItem('USER_NAME', user.displayName);
                localStorage.setItem('USER_LOGIN', userData.login);
                localStorage.setItem('USER_POST', userData.post);
                localStorage.setItem('USER_CASH', userData.coin || 0);

                // Устанавливаем состояние
                setNameI(user.displayName);
                setLoginI(userData.login);
                setPostI(userData.post);
                setCash(userData.coin);
                setLOGstatus('true');
                setShowRegistrationModal(false);
            } else {
                localStorage.setItem('LOGstatus', 'false');
                setShowRegistrationModal(true);
            }

            setName(user.displayName);
            setPost(user.email);
            setNumber(user.phoneNumber || '');
        } catch (error) {
            console.error("Error handling auth result:", error);
            alert("Authentication error: " + error.message);
        }
    };
    const auth = getAuth();
// Email/Password Login Handler
    const handleLogin = async () => {
        try {
            if (!mail || !password) {
                setVerifyERROR('Пожалуйста, введите почту и пароль');
                return;
            }

            await setPersistence(auth, browserLocalPersistence); // Устанавливаем постоянную сессию

            const userCredential = await signInWithEmailAndPassword(auth, mail, password);
            const user = userCredential.user;

            console.log("Пользователь авторизован:", user);

            // Загружаем данные пользователя из Firestore
            const userDocRef = doc(fs, 'Users', mail);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();

                // Сохраняем в localStorage
                localStorage.setItem('LOGstatus', 'true');
                localStorage.setItem('USER_NAME', userData.name);
                localStorage.setItem('USER_LOGIN', userData.login || '');
                localStorage.setItem('USER_POST', userData.post || '');
                localStorage.setItem('USER_CASH', userData.coin || 0);

                // Обновляем состояние
                setNameI(userData.name);
                setLoginI(userData.login || '');
                setPostI(userData.post || '');
                setLOGstatus('true');
                setCash(userData.coin || 0);
                setName(userData.name);
                setPost(userData.mail);
                setNumber(userData.phoneNumber || '');
            } else {
                setVerifyERROR('Ошибка: данные пользователя не найдены в Firestore');
            }
        } catch (error) {
            console.error("Ошибка входа:", error);
            setVerifyERROR('Ошибка входа: ' + error.message);
            localStorage.setItem('LOGstatus', 'false');
        }
    };
    // Initialize reCAPTCHA
    // const setupRecaptcha = () => {
    //     try {
    //         const auth = getAuth();
    //
    //         // Проверяем, существует ли уже reCAPTCHA
    //         if (!window.recaptchaVerifier) {
    //             // Проверяем, существует ли элемент #recaptcha-container
    //             const recaptchaElement = document.getElementById('recaptcha-container');
    //             if (!recaptchaElement) {
    //                 console.error("reCAPTCHA контейнер не найден в DOM");
    //                 setVerifyERROR('Ошибка: контейнер reCAPTCHA не найден.');
    //                 return null;
    //             }
    //
    //             // Создаём новый reCAPTCHA Verifier
    //             window.recaptchaVerifier = new RecaptchaVerifier(recaptchaElement, {
    //                 size: 'invisible',
    //                 callback: (response) => {
    //                     console.log("reCAPTCHA пройден", response);
    //                 }
    //             }, auth);
    //         }
    //
    //         return window.recaptchaVerifier;
    //     } catch (error) {
    //         console.error("Ошибка настройки reCAPTCHA:", error);
    //         setVerifyERROR('Ошибка настройки reCAPTCHA: ' + error.message);
    //         return null;
    //     }
    // };

    // Handle new user registration
    const handleNewRegistration = async () => {
        if (!password || !number || !mail || !name) {
            setVerifyERROR('Укажите все данные');
            return;
        }

        if (password.length <= 5) {
            setVerifyERROR('Пароль должен содержать не менее 6 знаков');
            return;
        }

        if (password !== password2) {
            setVerifyERROR('Пароли не совпадают');
            return;
        }

        if (!/^\+?[0-9]{10,15}$/.test(number)) {
            setVerifyERROR('Введите корректный номер телефона');
            return;
        }

        try {
            // Проверка, существует ли почта
            const docSnapshot = await getDoc(doc(fs, 'Users', mail));
            if (docSnapshot.exists()) {
                setVerifyERROR('Эта почта уже используется');
                return;
            }

            setVerifyERROR('');

            const auth = getAuth();
            const formattedNumber = number.startsWith('+') ? number : `+${number}`;

            try {
                // Передаем null вместо reCAPTCHA
                const confirmation = await signInWithPhoneNumber(auth, formattedNumber, null);
                setConfirmationResult(confirmation);
                setShowVerificationModal(true);
            } catch (error) {
                console.error("Ошибка при отправке кода:", error);
                setVerifyERROR(`Ошибка при отправке кода: ${error.message}`);
            }
        } catch (error) {
            console.error("Ошибка при регистрации:", error);
            setVerifyERROR(`Ошибка при регистрации: ${error.message}`);
        }
    };
    // Confirm verification code
    const confirmCode = async () => {
        if (!verificationCode) {
            setVerifyERROR("Введите код подтверждения");
            return;
        }

        try {
            await confirmationResult.confirm(verificationCode);

            // Create new user in Firestore
            const userDocRef = doc(fs, 'Users', mail);
            const newUser = {
                name,
                mail,
                number,
                password,
                status: "basic",
                coin: 0,
                login: login || mail.split('@')[0] // Fallback login if not provided
            };

            await setDoc(userDocRef, newUser);

            // Clear form fields
            setNumber('');
            setName('');
            setMail('');
            setPassword('');
            setPassword2('');
            setVerificationCode('');
            setShowVerificationModal(false);

            // Auto-login the new user
            localStorage.setItem('LOGstatus', 'true');
            localStorage.setItem('USER_NAME', name);
            localStorage.setItem('USER_LOGIN', login || mail.split('@')[0]);
            localStorage.setItem('USER_POST', mail);
            setNameI(name);
            setLoginI(login || mail.split('@')[0]);
            setPostI(mail);
            setLOGstatus('true');

            setTimeout(() => {
                window.location.assign("/");
            }, 1100);
        } catch (error) {
            console.error("Error confirming code:", error);
            setVerifyERROR(`Ошибка при подтверждении кода: ${error.message}`);
        }
    };

    // Handle registration with username (for Google login)
    const handleRegistration = async () => {
        try {
            if (!login) {
                setErrorMessage("Пожалуйста, введите имя пользователя");
                return;
            }

            // Check if login exists
            const q = query(collection(fs, 'Users'), where('login', '==', login));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                setErrorMessage("Это имя пользователя уже используется.");
                return;
            }

            // Create user document
            const userDocRef = doc(fs, 'Users', post);
            const userData = {
                name: name,
                post: post,
                number: number,
                login: login.toLowerCase(),
                age: age || '',
                status: "basic",
                coin: 0
            };

            await setDoc(userDocRef, userData);

            // Update local storage and state
            setShowRegistrationModal(false);
            setLOGstatus("true");
            localStorage.setItem("LOGstatus", "true");
            localStorage.setItem('USER_NAME', name);
            localStorage.setItem('USER_LOGIN', login);
            localStorage.setItem('USER_POST', post);
            setNameI(name);
            setLoginI(login);
            setPostI(post);
        } catch (error) {
            console.error("Error registering user:", error);
            setErrorMessage("Ошибка при регистрации: " + error.message);
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
        const element = document.getElementById('pas1');
        element.classList.toggle('active');
    };

    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
        const element = document.getElementById('pas2');
        element.classList.toggle('active');
    };

    // Toggle registration form
    const handleREG = () => {
        setShowReg(!showReg);
        setVerifyERROR('');
    };

    // Log out user
    const handleOut = async () => {
        const auth = getAuth();
        try {
            await signOut(auth); // Разлогинивание из Firebase
            localStorage.clear(); // Очистка локального хранилища
            window.location.reload(); // Перезагрузка страницы для сброса состояния
        } catch (error) {
            console.error("Ошибка выхода:", error);
        }
    };
    // Handle profile image upload
    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const storageRef = ref(storage, `USER_IMG/${postI}`);
            await uploadBytes(storageRef, file);
            const imageUrl = await getDownloadURL(storageRef);
            setProfileImage(imageUrl);
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    // Fetch user data on component mount
    useEffect(() => {
        const handleAuthResult = async () => {
            const userLogin = localStorage.getItem('USER_POST');
            if (!userLogin) {
                return; // Не меняем статус, если нет данных
            }

            try {
                const userDocRef = doc(fs, 'Users', userLogin);
                const docSnap = await getDoc(userDocRef);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setNameI(userData.name);
                    setLoginI(userData.login);
                    setPostI(userData.post);
                    setLOGstatus('true');
                    setCash(userData.coin);
                    setName(userData.name);
                    setPost(userData.mail);
                    setNumber(userData.phoneNumber || '');
                    localStorage.setItem('LOGstatus', 'true'); // Добавляем для надежности
                } else {
                    console.error("Данные пользователя не найдены");
                }
            } catch (error) {
                console.error("Ошибка при загрузке данных пользователя:", error);
            }
        };

        handleAuthResult();
    }, []);

    // Fetch user profile image
    useEffect(() => {
        const fetchUserData = async () => {
            if (!postI) {
                return;
            }

            try {
                const storageRef = ref(storage, `USER_IMG/${postI}`);
                const imageUrl = await getDownloadURL(storageRef);
                setProfileImage(imageUrl);
            } catch (error) {
                console.error("Error getting profile image:", error);
                setProfileImage(null);
            }
        };

        fetchUserData();
    }, [postI]);

    // Fetch user status
    useEffect(() => {
        const handleSubmit = async () => {
            if (!postI) {
                return;
            }

            try {
                const userDocRef = doc(fs, 'Users', postI);
                const docSnap = await getDoc(userDocRef);

                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setStatus(userData.status);

                    if (userData.status === "basic") {
                        setDisableButton("false");
                        localStorage.setItem("PARTNER_STATUS", "false");
                    }
                    if (userData.status === "review") {
                        setDisableButton("true");
                        localStorage.setItem("PARTNER_STATUS", "true");
                    }
                }
            } catch (error) {
                console.error("Error fetching user status:", error);
            }
        };

        handleSubmit();
    }, [postI]);

    // Fetch coins data
    const fetchCardCoins = async () => {
        try {
            const user = authentication.currentUser; // Получаем текущего пользователя
            if (!user) return; // Если пользователь не залогинен, прекращаем выполнение

            const userRef = doc(fs, "Users", user.email); // Берем email авторизованного юзера
            const userSnap = await getDoc(userRef);

            if (userSnap.exists() && userSnap.data().CardNumber) {
                const cardNum = userSnap.data().CardNumber;

                // Теперь загружаем баллы по номеру карты
                const coinsRef = doc(fs, "coins", cardNum);
                const coinsSnap = await getDoc(coinsRef);

                if (coinsSnap.exists()) {
                    const formattedCoins = Object.fromEntries(
                        Object.entries(coinsSnap.data()).map(([store, value]) => [store, Number(value) || 0])
                    );
                    setCoins(formattedCoins);
                } else {
                    setCoins({});
                }
            }
        } catch (error) {
            console.error("Ошибка загрузки баллов в UserPage:", error);
        }
    };

    useEffect(() => {
        fetchCardCoins()
    }, []);

    // Modal toggle functions
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleAddModal = () => {
        setAddModalStatus("");
        setAddModal(!addModal);
    };

    const handleFormChange = (event) => {
        setIsPhysical(event.target.value === 'physical');
    };

    // Submit partner status request
    const handleSubmit = async () => {
        const userDocRef = doc(fs, 'Users', postI);

        try {
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                if (userData.status === "basic") {
                    const phoneNumber = document.querySelector('input[placeholder="Номер телефона"]').value;
                    let additionalData = {};

                    if (isPhysical) {
                        const physicalNumber = document.querySelector('input[placeholder="ИИН"]').value;
                        additionalData = {
                            SK: physicalNumber
                        };
                    } else {
                        const legalNumber = document.querySelector('input[placeholder="Название Юр Адресса"]').value;
                        additionalData = {
                            IK: legalNumber
                        };
                    }

                    await updateDoc(userDocRef, {
                        status: 'review',
                        phoneNumber: phoneNumber,
                        ...additionalData
                    });

                    localStorage.setItem("PARTNER_STATUS", "true");
                    setDisableButton("true");
                    toggleModal();
                }
            }
        } catch (error) {
            console.error('Ошибка при обновлении статуса пользователя:', error);
        }
    };

    // Calculate totals for loyalty display
    const totalCoins = Object.values(coins || {}).reduce((total, coin) => total + coin, 0);
    const maxCoins = 700;
    const widthPercentage = totalCoins > 0 ? Math.min((totalCoins / maxCoins) * 100, 100) : 0;

    const minCoin = Object.values(coins || {}).length > 0
        ? Math.min(...Object.values(coins).filter(num => num > 0))
        : 0;

    const totalCoins2 = minCoin * 5;
    const maxCoins2 = 700;
    const widthPercentage2 = totalCoins2 > 0 ? Math.min((totalCoins2 / maxCoins2) * 100, 100) : 0;

    const totalSum = totalCoins + totalCoins2;
    const storeLogos = {
        "nadimi": "l1",
        "copacabana": "l2",
        "aromiitaliani": "l3",
        "poshnosh": "l4",
        "symposium": "l5",

    };

    return (
        <>
            <Bar/>
            <div className={"auth"}>
                {lOGstatus !== "true" && !showRegistrationModal && !showReg && (
                    <div className="google-login-container">

                        <div className={"auth_container"}>
                            <h1>{t('Login or Register')}</h1>
                            <h2>{t('To take advantage of all the possibilities')}</h2>
                        </div>
                        <div className="login-form-content-image">
                            <div className="login-form-image"></div>
                        </div>
                        <div className="login-form-body">
                            <h1>Log in to your account</h1>
                        </div>


                        <div className={"auth_container"}>

                            {/*<h2> {t('Email')}</h2>*/}
                            <input
                                type={"email"}
                                className={'number_i'}
                                autoComplete="off"
                                id={'Mail'}
                                placeholder={"Log in with an email"}
                                onChange={(e) => setMail(e.target.value)}
                            />
                            {/*<h2> {t('Password')}</h2>*/}
                            <div className={"passcont"}>

                                <input
                                    placeholder={"Password"}
                                    className={'number_i'}
                                    autoComplete="off"
                                    id={'Password'}
                                    type={passwordVisible ? 'text' : 'password'}
                                    va8lue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <div className={"pasicon"} id={"pas1"} onClick={togglePasswordVisibility}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye"
                                         width="24"
                                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                         fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                                        <path
                                            d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"/>
                                    </svg>
                                </div>
                            </div>
                            <div className={"reg_error_msg"}>
                                {verifyERROR}
                            </div>
                            <button className="google-login-btn2" onClick={handleLogin}>
                                {t('Login')}
                            </button>
                            <br/>

                        </div>
                        <button className="google-login-btn" onClick={handleGoogleLogin}>
                            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 89.97 91.8">

                                <path className="cls-111"
                                      d="M120,76.1a57.41,57.41,0,0,0-.8-9.3H75.9V84.5h24.8a20.86,20.86,0,0,1-9.2,13.9l14.8,11.5C115,101.8,120,90,120,76.1Z"
                                      transform="translate(-30.03 -29.1)"/>
                                <path className="cls-112"
                                      d="M75.9,120.9c12.4,0,22.8-4.1,30.4-11.1L91.5,98.4a27.35,27.35,0,0,1-15.6,4.4c-12,0-22.1-8.1-25.8-18.9L34.9,95.6A45.85,45.85,0,0,0,75.9,120.9Z"
                                      transform="translate(-30.03 -29.1)"/>
                                <path className="cls-113"
                                      d="M50.1,83.8a27.88,27.88,0,0,1,0-17.6L34.9,54.4a46,46,0,0,0,0,41.2Z"
                                      transform="translate(-30.03 -29.1)"/>
                                <path className="cls-114"
                                      d="M75.9,47.3a25,25,0,0,1,17.6,6.9L106.6,41A44.17,44.17,0,0,0,75.9,29.1a45.85,45.85,0,0,0-41,25.3L50.1,66.2A27.46,27.46,0,0,1,75.9,47.3Z"
                                      transform="translate(-30.03 -29.1)"/>
                            </svg>
                            {t('Login with Google')}
                        </button>


                        <div className={"Registration_button"}>
                            <div className={"Registraton_button_first"}>
                                {t('Dont have an account?')}
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round"
                                 className="">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M5 12l14 0"/>
                                <path d="M15 16l4 -4"/>
                                <path d="M15 8l4 4"/>
                            </svg>

                            <div className={"Registraton_button_sec"} onClick={handleREG}>
                                {t('Create an account')}
                            </div>

                        </div>
                    </div>
                )}

                <>
                    {/* Ваша форма регистрации */}

                    {/* Контейнер для reCAPTCHA */}

                    {showVerificationModal && (
                        <div className="modal">
                            <h2>Введите код подтверждения</h2>
                            <input
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                            <button onClick={confirmCode}>Подтвердить код</button>
                            <button onClick={() => setShowVerificationModal(false)}>Отмена</button>
                        </div>
                    )}
                </>


                {showReg && (
                    <Registration onBack={handleBack} />
                )}


                {showRegistrationModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <input
                                className={"login"}
                                placeholder={"Enter Login"}
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                                type={"text"}
                            />
                            {errorMessage &&
                                <div
                                    className="login_error-message"> {t(errorMessage)}</div>} {/* Display error message */}
                            <button className={"register_b"} onClick={handleRegistration}>Register</button>

                        </div>
                    </div>
                )}

                {lOGstatus === "true" && !showRegistrationModal && (
                    <div className={"User"}>
                        <div className={"User"}>
                            <div className={"User_container"}>
                                <div className={"User_img"} style={{backgroundImage: `url(${profileImage})`}}></div>
                                <div className={"User_text"}>
                                    {/*  <div className={"cash"}>
                                        {totalSum} Art coin
                                    </div>*/}
                                    <div className={"User_name"}>
                                        {nameI}
                                    </div>
                                    <div className={"User_ID"}>
                                        @{loginI}
                                    </div>
                                </div>

                            </div>


                            { <>

                                <div className="loyality">
                                    {Object.entries(coins).map(([store, coin]) => (
                                        <div key={store}
                                             className={`loyality_icon ${storeLogos[store] || "default-icon"}`}>
                                            <div className="loay_num">{coin}</div>

                                        </div>
                                    ))}
                                </div>
                                <div className={"loyal_result"}>
                                    <div
                                        className={"loyal_status"}

                                    >
                                        <div className={"loyal_number"}>
                                            {totalCoins} <div className={"art_coin"}>Art <br />coin</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"bonus_status"}>
                                    {minCoin} * 5 bonus
                                </div>
                                <div className={"loyal_result"}>
                                    <div
                                        className={"loyal_status status2"}

                                    >
                                        <div className={"loyal_number"}>
                                            {totalCoins2} <div className={"art_coin"}>Art <br />bonus</div>
                                        </div>


                                    </div>
                                </div>

                            </>}


                            <div className={"user_menu"}>

                                <div className={"user_menu_container"}>

                                    <div className={"user_menu_item"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             className="icon icon-tabler icons-tabler-outline icon-tabler-search">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969"/>
                                            <path d="M14 3.223a9.003 9.003 0 0 1 0 17.554"/>
                                            <path className="blade" d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592"/>
                                            <path className="blade"
                                                  d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305"/>
                                            <path className="blade" d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356"/>
                                            <path d="M12 8v4l3 3"/>
                                        </svg>

                                        {t('My orders')}
                                    </div>
                                    <div className={"user_menu_item"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 22 16" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round">

                                            <path className="blade"
                                                  d="M-5.68,145.18v.88H-25.76v-.88a3.55,3.55,0,0,1,3.55-3.55h13A3.55,3.55,0,0,1-5.68,145.18Z"
                                                  transform="translate(26.76 -140.63)"/>
                                            <path
                                                d="M-5.68,146.06v6.6a3.54,3.54,0,0,1-3.55,3.54h-13a3.54,3.54,0,0,1-3.55-3.54v-6.6Z"
                                                transform="translate(26.76 -140.63)"/>
                                            <path d="M-22.34,150.92h6.16" className="blade"
                                                  transform="translate(26.76 -140.63)"/>
                                            <path d="M-11.62,150.92h.56" className="blade"
                                                  transform="translate(26.76 -140.63)"/>
                                        </svg>

                                        {t('Wallet and Payment System')}
                                    </div>
                                    <div className={"user_menu_item"} onClick={() => fileInputRef.current.click()}>

                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 22 16" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round">

                                            <path className="cls-2"
                                                  d="M-58.22,145.24l5.14-5.14,5.14-5.14a2.82,2.82,0,0,0,0-4,2.82,2.82,0,0,0-4,0l-5.15,5.15-5.16,5.16"
                                                  transform="translate(66.44 -130.46)"/>
                                            <path className="cls-1" d="M-59.44,151.46v4h4" className="blade"
                                                  transform="translate(63.44 -140.46)"/>
                                            <line className="cls-2" x1="20.6" y1="7.1" x2="16.94" y2="10.76"
                                                  className="blade"/>
                                        </svg>
                                        {t('Change Profile Photo')}

                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                            ref={fileInputRef}
                                            style={{display: 'none'}}
                                        />

                                    </div>
                                    <Link to={"/"} className={"user_menu_item"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round">

                                            <circle className="cls-1" cx="12" cy="12" r="3.79" className="blade"/>
                                            <path className="cls-2"
                                                  d="M-56,106.26a1.87,1.87,0,0,1,3.63,0,1.87,1.87,0,0,0,2.26,1.38,2.2,2.2,0,0,0,.53-.22A1.87,1.87,0,0,1-47,110a1.87,1.87,0,0,0,.62,2.57,2.2,2.2,0,0,0,.53.22,1.87,1.87,0,0,1,0,3.63,1.86,1.86,0,0,0-1.37,2.26,1.86,1.86,0,0,0,.22.53,1.87,1.87,0,0,1-2.57,2.57,1.87,1.87,0,0,0-2.57.62,2.2,2.2,0,0,0-.22.53,1.87,1.87,0,0,1-3.63,0,1.87,1.87,0,0,0-2.26-1.37,1.65,1.65,0,0,0-.53.22,1.87,1.87,0,0,1-2.57-2.57,1.87,1.87,0,0,0-.63-2.57,1.65,1.65,0,0,0-.53-.22,1.87,1.87,0,0,1,0-3.63,1.88,1.88,0,0,0,1.38-2.26,1.91,1.91,0,0,0-.22-.53,1.87,1.87,0,0,1,2.57-2.57A1.87,1.87,0,0,0-56,106.26Z"
                                                  transform="translate(66.17 -102.59)"/>

                                        </svg>
                                        {t('Settings')}
                                    </Link>

                                    <Link to={"/"} className={"user_menu_item"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="icon icon-tabler icons-tabler-outline icon-tabler-info-square-rounded">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M12 9h.01" className="blade"/>
                                            <path d="M11 12h1v4h1" className="blade"/>
                                            <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"/>
                                        </svg>
                                        {t('FAQ')}
                                    </Link>
                                    <div className={"user_menu_item out_item"} onClick={handleOut}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-autofit-left">


                                            <path className="cls-2" d="M-42.44,103.06h-4.2"
                                                  transform="translate(60.34 -91.1)" className="blade"/>
                                            <path className="cls-2" d="M-45.8,105.59l-2.52-2.53,2.52-2.52"
                                                  transform="translate(60.34 -91.1)"/>
                                            <path className="cls-2" className="blade"
                                                  d="M-43.13,111.33h-7.76A3.35,3.35,0,0,1-54.24,108V98.21a3.35,3.35,0,0,1,3.35-3.34h7.76"
                                                  transform="translate(60.34 -91.1)"/>
                                        </svg>

                                        {t('Logout')}
                                    </div>

                                </div>
                                <br/>
                                <div className={"block2_container_sec paral"}>
                                    <div className={"block2_sec_text_main"}>
                                        {t('Loyalty program')}
                                    </div>
                                    <div className={"block2_sec_text_sec"}>
                                        {t('By connecting to the loyalty system you become our regular customer. Using this system you will receive bonuses and access to private events.')}
                                        <button className={"block2_sec_button"}
                                                onClick={() => handleXBarItemChange(7)}> {t('Read more')}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )}

            </div>

        </>


    );
};

export default UserPage;


