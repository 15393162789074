import React, { useState, useEffect } from "react";
import DeliveryWay from "./DeliveryWay";
const Bascket = () => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productQuantities, setProductQuantities] = useState({});
    const [totalPrice, setTotalPrice] = useState(0); // Состояние для общей суммы
    const [isOrdering, setIsOrdering] = useState(false);

    useEffect(() => {
        // Загружаем данные из localStorage
        const storedProducts = JSON.parse(localStorage.getItem("selectedProducts")) || [];
        const storedQuantities = JSON.parse(localStorage.getItem("productQuantities")) || {};

        setSelectedProducts(storedProducts);
        setProductQuantities(storedQuantities);
    }, []);
    useEffect(() => {
        // Пересчитываем сумму при изменении товаров или их количества
        const total = selectedProducts.reduce((sum, product) => {
            const quantity = productQuantities[product.id] || 1;
            return sum + product.price * quantity;
        }, 0);
        setTotalPrice(Number(total.toFixed(2))); // Округляем до двух знаков после запятой
    }, [selectedProducts, productQuantities]);
    const handleRemove = (productId) => {
        const updatedProducts = selectedProducts.filter(p => p.id !== productId);
        const updatedQuantities = { ...productQuantities };
        delete updatedQuantities[productId];

        setSelectedProducts(updatedProducts);
        setProductQuantities(updatedQuantities);

        // Обновляем localStorage
        localStorage.setItem("selectedProducts", JSON.stringify(updatedProducts));
        localStorage.setItem("productQuantities", JSON.stringify(updatedQuantities));
    };

    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 1) + 1
        }));
        localStorage.setItem("productQuantities", JSON.stringify({
            ...productQuantities,
            [productId]: (productQuantities[productId] || 1) + 1
        }));
    };

    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] > 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
            localStorage.setItem("productQuantities", JSON.stringify({
                ...productQuantities,
                [productId]: productQuantities[productId] - 1
            }));
        }
    };
    if (isOrdering && totalPrice !== undefined) {
        return <DeliveryWay onBack={() => setIsOrdering(false)} totalPrice={totalPrice} selectedProducts={selectedProducts} productQuantities={productQuantities}/>;
    }
    console.log(totalPrice)
    return (
        <div className="bascket-container">

            {selectedProducts.length > 0 ? (
                <div className="basket-grid">
                    {selectedProducts.map((product) => (
                        <div key={product.id} className="product-card">
                            <div className="menu_item_card_img">
                                <img
                                    loading="lazy"
                                    src={product.imageUrl}
                                    alt={product.title}
                                    className="product-image"
                                />
                            </div>

                            <div className="product-info">
                                <div className="product_title">{product.title}</div>
                                <div className="product_description">{product.description}</div>

                                <div className="quantity-controls">
                                    <button onClick={() => decreaseQuantity(product.id)}>-</button>
                                    <span>{productQuantities[product.id] || 1}</span>
                                    <button onClick={() => increaseQuantity(product.id)}>+</button>
                                </div>

                                <div className="product-price">
                                    <div className="product_price_text">
                                        {(product.price * (productQuantities[product.id] || 1)).toFixed(2)}
                                        <div className="price_lari">ლ</div>
                                    </div>

                                    <div className="basket" onClick={() => handleRemove(product.id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="iconsa icon-tabler icons-tabler-outline icon-tabler-basket">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M18 6l-12 12"/>
                                            <path d="M6 6l12 12"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            ) : (
                <h2 className={'Bascket-title'}>Корзина пуста</h2>
            )}

            {selectedProducts.length > 0 ? (
                <>
                    <h1 className={'Bascket-price'}>Total price: {totalPrice} ლ</h1>
                    <button onClick={() => setIsOrdering(true)}>Make order</button>
                </>
            ) : null}

        </div>
    );
};

export default Bascket;