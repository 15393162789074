import React from 'react';
import { fs } from '../../firebase'; // Убедись, что импортируешь Firestore
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const FirebaseOrder = ({ orderData, selectedProducts, productQuantities, totalPrice, orderReceipt }) => {
    const auth = getAuth(); // Получаем экземпляр auth
    const user = auth.currentUser; // Получаем текущего пользователя

    const handleOrderSubmit = async () => {
        if (!user) {
            alert('Необходимо войти в аккаунт');
            return;
        }

        // Проверка данных перед отправкой
        const products = selectedProducts.map(product => {
            const quantity = productQuantities[product.id] || 1;
            const name = product.title || 'Неизвестный товар'; // Если name не существует
            const price = product.price || 0; // Если price не существует, ставим 0

            return {
                productId: product.id, // Мы сохраняем ID продукта
                quantity,
                name,
                price,
            };
        });

        // Формируем заказ
        const order = {
            ...orderData,
            orderReceipt: orderReceipt,
            totalPrice: totalPrice,
            products,
            userId: user.uid,
            status: "pending",
            createdAt: serverTimestamp(), // Время заказа
        };

        console.log("Отправляемый заказ:", JSON.stringify(order, null, 2)); // Логируем данные

        try {
            // Добавляем заказ в коллекцию Firestore
            await addDoc(collection(fs, "DeliveryOrder"), order);
            alert('Заказ успешно оформлен!');
        } catch (error) {
            console.error("Ошибка при отправке заказа:", error);
            alert('Ошибка при оформлении заказа');
        }
    };

    return (
        <div>
            <button onClick={handleOrderSubmit}>Make order({totalPrice}ლ)</button>
        </div>
    );
};

export default FirebaseOrder;