import React, { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { fs, authentication, provider, signInWithPopup } from "../firebase";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";
import '../css/LoyaltyCrm.css'
const Registration = ({ onBack }) => {
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [error, setError] = useState('');
    const [isCookieAccepted, setIsCookieAccepted] = useState(false);
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleRegister = async () => {
        if (!name || !mail || !number || !password || !password2) {
            setError('Заполните все поля');
            return;
        }

        if (password !== password2) {
            setError('Пароли не совпадают');
            return;
        }

        if (!isCookieAccepted || !isPrivacyPolicyAccepted) {
            setError('Необходимо согласие с условиями');
            return;
        }

        // if (!captchaValue) {
        //     setError('Пожалуйста, подтвердите, что вы не робот');
        //     return;
        // }

        try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, mail, password);
            const user = userCredential.user;

            const userRef = doc(fs, 'Users', mail);
            await setDoc(userRef, {
                name,
                mail,
                number,
                uid: user.uid
            });

            alert('Регистрация успешна!');
            onBack();
        } catch (error) {
            console.error("Ошибка при регистрации:", error);
            setError('Ошибка при регистрации: ' + error.message);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(authentication, provider);
            const user = result.user;

            const phoneNumber = prompt("Пожалуйста, введите ваш номер телефона:");

            const userRef = doc(fs, 'Users', user.email);
            await setDoc(userRef, {
                name: user.displayName,
                mail: user.email,
                number: phoneNumber,
                uid: user.uid
            });

            onBack();
            alert('Вы успешно вошли с Google!');
        } catch (error) {
            console.error("Ошибка при входе через Google:", error);
            setError('Ошибка при входе через Google: ' + error.message);
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div className="registration-modal">
            <h2>Регистрация</h2>
            <input type="text" placeholder="Имя" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="email" placeholder="Email" value={mail} onChange={(e) => setMail(e.target.value)} />
            <input type="text" placeholder="Номер телефона" value={number} onChange={(e) => setNumber(e.target.value)} />
            <div className={"passcont"}>
                <input
                    placeholder={"Пароль"}
                    className={'number_i'}
                    autoComplete="off"
                    id={'Password'}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className={"pasicon"} id={"pas1"} onClick={togglePasswordVisibility}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye"
                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"/>
                    </svg>
                </div>
            </div>
            <div className={"passcont"}>
                <input
                    placeholder={"Повторите пароль"}
                    className={'number_i'}
                    autoComplete="off"
                    id={'Password2'}
                    type={showPassword2 ? 'text' : 'password'}
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                />
                <div className={"pasicon"} id={"pas2"} onClick={togglePasswordVisibility2}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye"
                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"/>
                    </svg>
                </div>
            </div>
            <div className="auth-checkbox-container">
                <label>
                    <input
                        type="checkbox"
                        checked={isCookieAccepted}
                        onChange={(e) => setIsCookieAccepted(e.target.checked)}
                    />
                    Согласие на использование куки
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={isPrivacyPolicyAccepted}
                        onChange={(e) => setIsPrivacyPolicyAccepted(e.target.checked)}
                    />
                    Согласие с политикой конфиденциальности
                </label>
            </div>
            {/*<ReCAPTCHA*/}
            {/*    sitekey="6Lex8OwqAAAAAK35k3eT68tynCBwC3b4z48hxNS3"*/}
            {/*    onChange={handleCaptchaChange}*/}
            {/*/>*/}
            <button onClick={handleRegister}>Зарегистрироваться</button>
            <button className="google-login-btn" onClick={handleGoogleLogin}>
                <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 89.97 91.8">
                    <path className="cls-111"
                          d="M120,76.1a57.41,57.41,0,0,0-.8-9.3H75.9V84.5h24.8a20.86,20.86,0,0,1-9.2,13.9l14.8,11.5C115,101.8,120,90,120,76.1Z"
                          transform="translate(-30.03 -29.1)"/>
                    <path className="cls-112"
                          d="M75.9,120.9c12.4,0,22.8-4.1,30.4-11.1L91.5,98.4a27.35,27.35,0,0,1-15.6,4.4c-12,0-22.1-8.1-25.8-18.9L34.9,95.6A45.85,45.85,0,0,0,75.9,120.9Z"
                          transform="translate(-30.03 -29.1)"/>
                    <path className="cls-113"
                          d="M50.1,83.8a27.88,27.88,0,0,1,0-17.6L34.9,54.4a46,46,0,0,0,0,41.2Z"
                          transform="translate(-30.03 -29.1)"/>
                    <path className="cls-114"
                          d="M75.9,47.3a25,25,0,0,1,17.6,6.9L106.6,41A44.17,44.17,0,0,0,75.9,29.1a45.85,45.85,0,0,0-41,25.3L50.1,66.2A27.46,27.46,0,0,1,75.9,47.3Z"
                          transform="translate(-30.03 -29.1)"/>
                </svg>
                Register with google
            </button>
            <button onClick={onBack} className="back-button">Назад</button>
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default Registration;