import React, { useState, useEffect } from 'react';
import '../css/loyaltypage.css';
import { authentication, fs } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc,setDoc } from "firebase/firestore";

const LoyaltyPage = ({ handleXBarItemChange }) => {
    const [step, setStep] = useState(1);
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [selectedCardType, setSelectedCardType] = useState('');
    const [message, setMessage] = useState('');
    const [userCard, setUserCard] = useState(null);
    const [coins, setCoins] = useState([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authentication, async (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                await fetchUserCard(currentUser.email);
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchUserCard = async (email) => {
        try {
            const userRef = doc(fs, "Users", email);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists() && userSnap.data().CardNumber) {
                const cardNum = userSnap.data().CardNumber;
                setUserCard(cardNum);
                await fetchCardCoins(cardNum);
            }
        } catch (error) {
            console.error("Ошибка загрузки карты пользователя:", error);
        }
    };

    const fetchCardCoins = async (cardNum) => {
        try {
            const coinsRef = doc(fs, "coins", cardNum);
            const coinsSnap = await getDoc(coinsRef);
            if (coinsSnap.exists()) {
                // Преобразуем значения в числа, чтобы избежать конкатенации строк
                const formattedCoins = Object.fromEntries(
                    Object.entries(coinsSnap.data()).map(([store, value]) => [store, Number(value) || 0])
                );
                setCoins(formattedCoins);
            } else {
                setCoins({});
            }
        } catch (error) {
            console.error("Ошибка загрузки баллов:", error);
        }
    };

    const handleNextStep = () => {
        if (!user) {
            setError("Вам нужно авторизоваться для добавления карты");
        } else {
            setStep(2);
            setError('');
        }
    };

    const handleCardSelection = (type) => {
        setSelectedCardType(type);
        setStep(3);
    };

    const handleSubmit = async () => {
        if (!cardNumber) {
            setMessage("Введите номер карты.");
            return;
        }

        if (!selectedCardType) {
            setMessage("Выберите тип карты.");
            return;
        }

        try {
            const cardRef = doc(fs, selectedCardType, cardNumber);
            const cardSnap = await getDoc(cardRef);

            if (!cardSnap.exists()) {
                setMessage("Карта с таким номером не найдена.");
                return;
            }

            const cardData = cardSnap.data();

            if (cardData.uid) {
                setMessage("Эта карта уже привязана к другому аккаунту.");
                return;
            }

            // Запись данных пользователя в документ карты
            await updateDoc(cardRef, {
                uid: user.uid,
                mail: user.email,
                name: user.displayName || "Не указано",
                phone: user.phoneNumber || "Не указан"
            });

            // Запись номера карты в документ пользователя
            const userRef = doc(fs, "Users", user.email);
            await updateDoc(userRef, {
                CardNumber: cardNumber
            });

            // **Добавляем карту в коллекцию `coins`, если ее еще нет**
            const coinsRef = doc(fs, "coins", cardNumber);
            const coinsSnap = await getDoc(coinsRef);

            if (!coinsSnap.exists()) {
                await setDoc(coinsRef, {  // ✅ Правильно: setDoc создает новый документ
                    "nadimi": 0,
                    "copacabana": 0,
                    "aromiitaliani": 0,
                    "poshnosh": 0,
                    "symposium": 0
                });
            }


            setMessage("Карта успешно привязана!");
            await fetchUserCard(user.email);
            setStep(1); // Вернуться на главный экран

        } catch (error) {
            console.error("Ошибка при привязке карты:", error);
            setMessage("Ошибка привязки карты. Попробуйте снова.");
        }
    };
    // Корректный расчет значений
    const totalCoins = Object.values(coins).reduce((total, coin) => total + coin, 0);
    const maxCoins = 700;
    const widthPercentage = totalCoins > 0 ? Math.min((totalCoins / maxCoins) * 100, 100) : 0;

    const minCoin = Object.values(coins).length > 0
        ? Math.min(...Object.values(coins).filter(num => num > 0))
        : 0;

    const totalCoins2 = minCoin * 5;
    const widthPercentage2 = totalCoins2 > 0 ? Math.min((totalCoins2 / maxCoins) * 100, 100) : 0;
    const storeLogos = {
        "nadimi": "l1",
        "copacabana": "l2",
        "aromiitaliani": "l3",
        "poshnosh": "l4",
        "symposium": "l5",

    };
    const benefitIcons = [
        "✅", // Иконка для приоритетной брони
        "🚗", // Иконка для трансфера
        "🍽️", // Иконка для дегустации
        "🎂", // Иконка для дня рождения
        "🚀", // Иконка для доставки
        "🍾", // Иконка для кейтеринга
        "⛵", // Иконка для яхтенных вечеринок
        "💰", // Иконка для оплаты баллами
        "🍴"  // Иконка для специальных дней
    ];
    const benefitsTitles = [
        "Priority Booking",
        "Free Transfer",
        "Tasting of New Dishes",
        "50% Birthday Discount",
        "VIP Delivery",
        "Outdoor Catering",
        "Yacht Parties, Lunches, and Dinners",
        "Bill Payment with Points",
        "Special Dish Days",
    ];

    const benefitsDescriptions = [
        "The ability to reserve the best tables in advance.",
        "For reservations of 200 GEL or more and groups of at least four guests.",
        "Try new dishes and drinks before they appear on the main menu.",
        "Applies to the entire table.",
        "Free delivery for program participants.",
        "Special conditions for organizing banquets and events.",
        "Access to exclusive events.",
        "Up to 50% of the bill can be paid with ART COINS.",
        "Each restaurant will offer unique promotions on different days of the week.",
    ];

    return (
        <div className="LoyaltyPage-container">
            <div className={'main_blocks_text'}>Loyalty program</div>

            <div className="loyalpage-container">
                <div className="loyalpage-welcome">
                    <h2 className="loyalpage-title">
                        Welcome to the Exclusive Europe Square Loyalty Program!
                    </h2>
                    <p className="loyalpage-lead">
                        Enjoy your favorite restaurants, collect bonus points, and unlock unique privileges.
                    </p>
                </div>

                <div className="loyalpage-section">
                    <div className="loyalpage-icon">🔹</div>
                    <h3 className="loyalpage-subtitle">How Are Points Earned?</h3>
                    <p className="loyalpage-text">
                        Every visit to Europe Square restaurants is rewarded!
                    </p>

                    <ul className="loyalpage-list">
                        <li className="loyalpage-list-item">
                            <span className="loyalpage-highlight">1 GEL on the bill = 1 ART COIN</span>
                        </li>
                        <li className="loyalpage-list-item">
                            Points are credited only at the restaurant where the bill was paid.
                        </li>
                    </ul>
                </div>

                <div className="loyalpage-section loyalpage-bonus">
                    <div className="loyalpage-icon">🎁</div>
                    <h3 className="loyalpage-subtitle">Additional Bonus</h3>
                    <div className="loyalpage-formula">
                        <p className="loyalpage-text">
                            Bonus = (Minimum number of points across all restaurants) × 5
                        </p>
                        <div className="loyalpage-note">
                            The more points in different restaurants, the higher your bonus!
                        </div>
                    </div>
                </div>
            </div>

            {userCard ? (
                <>
                    <div className={'user-card-wrapper'}>
                        <div className={'user-card-info'}>
                            <div className={'user-card-title'}>Your card: {userCard}</div>

                            {/* Отображение баллов и бонусов */}
                            <div className="loyality1">
                                {Object.entries(coins).map(([store, coin]) => (
                                    <div key={store}
                                         className={`loyality_icon ${storeLogos[store] || "default-icon"}`}>
                                        <div className="loay_num">{coin}</div>

                                    </div>
                                ))}
                            </div>


                            {totalCoins > 0 && (
                                <div className={"loyal-summary"}>
                                    <div className={"loyal-progress"}>
                                        <div className={"loyal-value"}>
                                            {totalCoins}
                                            <div className={"loyal-currency"}>Art <br/>coin</div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {totalCoins2 > 0 && (
                                <>
                                    <div className={"bonus-info"}>
                                        {minCoin} * 5 bonus
                                    </div>

                                    <div className={"bonus-summary"}>
                                        <div className={"bonus-progress"}>
                                            <div className={"bonus-value"}>
                                                {totalCoins2}
                                                <div className={"bonus-currency"}>Art <br/>bonus</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>


                </>
            ) : step === 1 ? (
                <>

                    {error && (
                        <div className="error-loyalty-message" onClick={() => handleXBarItemChange(4)}>
                            <span>{error}</span>
                        </div>
                    )}
                    <div className={'loyalty-button-container'}>
                        <button className={'become-beneficcer'} onClick={handleNextStep}>Add loyalty card</button>
                    </div>
                </>
            ) : step === 2 ? (
                <div className={'choose-card-container'}>
                    <div className={'choose-card-title'}>Выберите тип карты</div>
                    <button className={'silver-card-button'} onClick={() => handleCardSelection("SilverCards")}>
                        Серебряная карта
                    </button>
                    <button className={'gold-card-button'} onClick={() => handleCardSelection("GoldCards")}>
                        Золотая карта
                    </button>
                    <button className={'back-button'} onClick={() => setStep(1)}>Back</button>
                </div>
            ) : (
                <div className={'enter-card-code-container'}>
                    <div className={'enter-card-title'}>Введите код карты</div>
                    <input
                        type="text"
                        placeholder="Код карты"
                        className={'card-code-input'}
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                    />
                    {message && <div className="message">{message}</div>}
                    <button className={'submit-card-button'} onClick={handleSubmit}>Submit</button>
                    <button className={'back-button'} onClick={() => setStep(2)}>Back</button>
                </div>
            )}
            <div className={'Benefits-main-text'}>🎁 Member Privileges</div>

            <div className={'benefits-container'}>
                {[...Array(9)].map((title, index) => (
                    <div key={index} className="benefits-item">
                        <div className="benefits-title">
                            <span className="benefit-icon">{benefitIcons[index]}</span>
                            {benefitsTitles[index]}
                        </div>
                        <div className="benefits-text">{benefitsDescriptions[index]}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LoyaltyPage;