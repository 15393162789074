import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fs } from '../../firebase';
import { doc, getDoc, collection, getDocs, query, where, updateDoc } from 'firebase/firestore';

const LoyaltyCrm = () => {
    const navigate = useNavigate();
    const [cards, setCards] = useState([]);
    const [filteredCards, setFilteredCards] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editing, setEditing] = useState({});
    const [inputValue, setInputValue] = useState('');

    const fetchUserData = async (uid) => {
        try {
            const usersRef = collection(fs, "Users");
            const q = query(usersRef, where("uid", "==", uid));
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs[0]?.data() || null;
        } catch (error) {
            console.error("Error fetching user:", error);
            return null;
        }
    };

    const fetchCoinsData = async (cardId) => {
        try {
            const coinsRef = doc(fs, "coins", cardId);
            const coinsSnap = await getDoc(coinsRef);
            return coinsSnap.exists() ? coinsSnap.data() : null;
        } catch (error) {
            console.error("Error fetching coins:", error);
            return null;
        }
    };

    const handleEditClick = (cardId, store) => {
        setEditing({ [cardId + '-' + store]: true });
        setInputValue('');
    };

    const handleConfirm = (cardId, store, operation) => {
        if (inputValue && !isNaN(inputValue)) {
            const currentValue = cards.find(c => c.id === cardId)?.coins[store] || 0;
            const newValue = operation === 'add'
                ? Number(currentValue) + Number(inputValue)
                : Math.max(0, Number(currentValue) - Number(inputValue));
            updateCoin(cardId, store, newValue);
        }
        setEditing({});
    };

    const updateCoin = async (cardId, store, newValue) => {
        try {
            const coinsRef = doc(fs, 'coins', cardId);
            await updateDoc(coinsRef, { [store]: newValue });

            setCards(prev => prev.map(card =>
                card.id === cardId
                    ? { ...card, coins: { ...card.coins, [store]: newValue } }
                    : card
            ));
        } catch (error) {
            console.error('Ошибка обновления баллов:', error);
        }
    };

    useEffect(() => {
        const fetchAllCards = async () => {
            try {
                const allCards = [];
                const cardTypes = ['Silver', 'Gold'];

                for (const type of cardTypes) {
                    const querySnapshot = await getDocs(collection(fs, `${type}Cards`));
                    for (const docSnap of querySnapshot.docs) {
                        const cardData = docSnap.data();
                        allCards.push({
                            id: docSnap.id,
                            type,
                            ...cardData,
                            user: await fetchUserData(cardData.uid),
                            coins: await fetchCoinsData(docSnap.id)
                        });
                    }
                }
                setCards(allCards);
                setFilteredCards(allCards);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchAllCards();
    }, []);

    useEffect(() => {
        const results = cards.filter(card => {
            const search = searchTerm.toLowerCase();
            return (
                card.id.includes(search) ||
                (card.user?.mail?.toLowerCase().includes(search)) ||
                (card.user?.phone?.includes(searchTerm)) ||
                (card.user?.name?.toLowerCase().includes(search)) ||
                card.uid.toLowerCase().includes(search)
            );
        });
        setFilteredCards(results);
    }, [searchTerm, cards]);

    if (loading) return <div>Загрузка данных...</div>;
    if (error) return <div>Ошибка: {error}</div>;

    return (
        <div className="loyalty-crm-container">
            <button onClick={() => navigate(-1)}>← Назад</button>
            <h1>Loyalty CRM System</h1>

            <input
                type="text"
                placeholder="Поиск по карте, email, телефону или имени"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <div className="cards-list">
                {filteredCards.map(card => (
                    <div key={card.id} className={'loyal-card'}>
                        <h3>{card.type} Card: {card.id}</h3>
                        <p>UID: {card.uid}</p>

                        {card.user && (
                            <div>
                                <h4>Данные пользователя</h4>
                                <p>Имя: {card.user.name}</p>
                                <p>Email: {card.user.mail}</p>
                                <p>Телефон: {card.user.phone}</p>
                            </div>
                        )}

                        {card.coins && (
                            <div>
                                <h4>Баллы</h4>
                                {Object.entries(card.coins).map(([store, value]) => (
                                    <div key={store} className="coin-control">
                                        <span className="store-name">{store}: {value}</span>
                                        {editing[card.id + '-' + store] ? (
                                            <div className="edit-controls">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    value={inputValue}
                                                    onChange={(e) => setInputValue(e.target.value)}
                                                    placeholder="Введите сумму"
                                                />
                                                <button onClick={() => handleConfirm(card.id, store, 'add')} className="confirm-btn">+</button>
                                                <button onClick={() => handleConfirm(card.id, store, 'subtract')} className="confirm-btn">-</button>
                                            </div>
                                        ) : (
                                            <button onClick={() => handleEditClick(card.id, store)} className="add-btn">Изменить</button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LoyaltyCrm;