import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Link } from "react-router-dom";
const dishTypes = {
    Aromiitaliani: [
        "Breakfast", "Antipasti", "Zuppa and Insalatta", "Primi Piatti",
        "Pizza", "Secondi Piatti", "Dolce, Gelato and Bakery", "Bar"
    ],
    Copacabana: ["Cozinha", "Sushi Bar", "Bar"],
    Symposium: ["Kitchen", "Wine", "Alcohol", "Soft Drinks"],
    Poshnosh: ["Sandwiches and Wraps", "Tex Mex", "Boss Burger", "Shawarma", "Bakery", "Sides", "Sauces and Dips", "Drinks"],
    Nadimi: ["Cold dishes", "Hot Dishes", "Kakhetian BBQ", "Khinkali", "Garnish", "Bakery", "Beverages"],
    Cave: ["Menu", "Events"]
};
function EditAdd() {
    const { companyId, productId } = useParams();
    const [product, setProduct] = useState(null);
    const [company, setCompany] = useState('');


    const [title, setTitle] = useState('');
    const [titleGE, setTitleGE] = useState('');
    const [titleIT, setTitleIT] = useState('');
    const [titleAR, setTitleAR] = useState('');
    const [titleTR, setTitleTR] = useState('');
    const [titleRU, setTitleRU] = useState('');


    const [price, setPrice] = useState('');



    const [description, setDescription] = useState('');
    const [descriptionGE, setDescriptionGE] = useState('');
    const [descriptionIT, setDescriptionIT] = useState('');
    const [descriptionAR, setDescriptionAR] = useState('');
    const [descriptionTR, setDescriptionTR] = useState('');
    const [descriptionRU, setDescriptionRU] = useState('');



    const [selectedFile, setSelectedFile] = useState(null);
    const [type, setType] = useState('');
    const [sub, setSub] = useState('');
    const [isPopular, setIsPopular] = useState(false);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productDocRef = doc(getFirestore(), companyId, productId);
                const docSnap = await getDoc(productDocRef);

                if (docSnap.exists()) {
                    const productData = docSnap.data();
                    setProduct(productData);
                    setTitle(productData.title);
                    setTitleGE(productData.titleGE);
                    setTitleIT(productData.titleIT);
                    setTitleAR(productData.titleAR);
                    setTitleTR(productData.titleTR);
                    setTitleRU(productData.titleRU);

                    setPrice(productData.price);
                    setCompany(companyId);

                    setDescription(productData.description);
                    setDescriptionGE(productData.descriptionGE);
                    setDescriptionIT(productData.descriptionIT);
                    setDescriptionAR(productData.descriptionAR);
                    setDescriptionTR(productData.descriptionTR);
                    setDescriptionRU(productData.descriptionRU);

                    setType(productData.type);
                    setSub(productData.sub);
                    setIsPopular(productData.popular || false);
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error("Error fetching product: ", error);
            }
        };

        fetchProduct();
    }, [companyId, productId]);


    const handleSaveProduct = async () => {
        try {
            const updatedProduct = {
                title,
                titleGE,
                titleIT,
                titleAR,
                titleTR,
                titleRU,
                sub,
                type,
                description,
                descriptionGE,
                descriptionIT,
                descriptionAR,
                descriptionTR,
                descriptionRU,
                price,
                imageUrl: product.imageUrl,
                popular: isPopular,
            };

            if (selectedFile) {
                const storage = getStorage();
                const storageRef = ref(storage, `IMG/${productId}`);
                await uploadBytes(storageRef, selectedFile);
                const fileUrl = await getDownloadURL(storageRef);
                updatedProduct.imageUrl = fileUrl;
            }

            const productDocRef = doc(getFirestore(), companyId, productId);
            await updateDoc(productDocRef, updatedProduct);

            alert("Product updated successfully!");
            window.history.back();
        } catch (error) {
            console.error("Error updating product: ", error);
            alert("Error updating product. Please try again.");
        }
    };

    const [productQuantities, setProductQuantities] = useState(() => {
        const localData = localStorage.getItem("productQuantities");
        return localData ? JSON.parse(localData) : {};
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const handleImageClick = (product) => {
        setSelectedProduct(product);
        setModalOpen(true);
    };
    const closeModal = () => {
        setSelectedProduct(null);
        setModalOpen(false);
    };
    const decreaseQuantity = (productId) => {
        if (productQuantities[productId] > 1) {
            setProductQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: prevQuantities[productId] - 1
            }));
        }
    };
    const increaseQuantity = (productId) => {
        setProductQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 0) + 1
        }));
    };
    if (!product) {
        return <div className={"loading"}>Loading...</div>;
    }

    return (
        <div className="main p10">
            <Link to={"/fastDeliveryCRM"} className={"products_back_button"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                     viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                     strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M15 6l-6 6l6 6"/>
                </svg>
                {('Back')}
            </Link>

            <div className="group">
                <div className={"group_descr"}>Company</div>
                <select value={company} onChange={(e) => setCompany(e.target.value)}>
                    <option value="" disabled selected>{"Company"}</option>
                    <option value="Nadimi">Nadimi</option>
                    <option value="Copacabana">Copacabana</option>
                    <option value="Aromiitaliani">Aromiitaliani</option>
                    <option value="Poshnosh">Poshnosh</option>
                    <option value="Symposium">Symposium</option>
                    <option value="Cave">Cave</option>
                </select>
                <div className={"group_descr"}>Type of dish</div>
                <select onChange={(e) => setType(e.target.value)} disabled={!company}>
                    <option value="" disabled selected>Type of dish</option>
                    {company && dishTypes[company].map((dish) => (
                        <option key={dish} value={dish}>{dish}</option>
                    ))}
                </select>
                <div className={"group_descr"}>Sub Category</div>
                <input value={sub} placeholder={"Subcategory"} onChange={(e) => setSub(e.target.value)}/>


                <div className={"padtop"}>
                    <div className={"group_descr "}>Title EN</div>

                    <input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Название"/>

                    <div className="group_descr">Title GE</div>
                    <input
                        value={titleGE}
                        onChange={(e) => setTitleGE(e.target.value)}

                    />

                    <div className="group_descr">Title IT</div>
                    <input
                        value={titleIT}
                        onChange={(e) => setTitleIT(e.target.value)}

                    />

                    <div className="group_descr">Title AR</div>
                    <input
                        value={titleAR}
                        onChange={(e) => setTitleAR(e.target.value)}

                    />

                    <div className="group_descr">Title TR</div>
                    <input
                        value={titleTR}
                        onChange={(e) => setTitleTR(e.target.value)}

                    />

                    <div className="group_descr">Title RU</div>
                    <input
                        value={titleRU}
                        onChange={(e) => setTitleRU(e.target.value)}

                    />
                </div>

                <div className={"padtop"}>
                    <div className="group_descr padtop">Description EN</div>
                    <input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description GE</div>
                    <input
                        value={descriptionGE}
                        onChange={(e) => setDescriptionGE(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description IT</div>
                    <input
                        value={descriptionIT}
                        onChange={(e) => setDescriptionIT(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description AR</div>
                    <input
                        value={descriptionAR}
                        onChange={(e) => setDescriptionAR(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description TR</div>
                    <input
                        value={descriptionTR}
                        onChange={(e) => setDescriptionTR(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description RU</div>
                    <input
                        value={descriptionRU}
                        onChange={(e) => setDescriptionRU(e.target.value)}
                        placeholder="Ingredients"
                    />
                </div>
                <div className={"padtop"}>
                    <div className={"group_descr"}>Price</div>
                    <input value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Price"/>
                </div>
                <div className={"group_descr"}>Images</div>
                <input type="file" className="IMG_input" onChange={(e) => setSelectedFile(e.target.files[0])}/>

                <div className={"padtop"}>
                    <div className={"popular"}>
                        Popular
                        <input
                            type="checkbox"
                            checked={isPopular}
                            onChange={(e) => setIsPopular(e.target.checked)}
                        />

                    </div>
                </div>
                <button className="Add_btn" onClick={handleSaveProduct}>
                    Save changes
                </button>
            </div>

            <div className="zcrmp">
                <div key={product.id} className="product-card">
                    <div className="menu_item_card_img">
                        <img
                            loading={"lazy"}
                            src={product.imageUrl}
                            alt={product.title}
                            className="product-image"
                            onClick={() => handleImageClick(product)}/>
                    </div>


                    <div className="product-info">


                        <div className="product_title"
                             onClick={() => handleImageClick(product)}>{product.title}</div>
                        <div className="product_description"
                             onClick={() => handleImageClick(product)}>{description}</div>


                        <div className="product-price">
                            <div className="product_price_text"
                                 onClick={() => handleImageClick(product)}>
                                {(product.price * (productQuantities[product.id] || 1)).toFixed(2)}
                                <div className="price_lari">ლ</div>
                            </div>



                        </div>

                    </div>

                </div>
            </div>
            {modalOpen && selectedProduct && (() => {
                const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

                const descriptions = {
                    en: selectedProduct.description,
                    ge: selectedProduct.descriptionGE,
                    it: selectedProduct.descriptionIT,
                    ar: selectedProduct.descriptionAR,
                    tr: selectedProduct.descriptionTR,
                    ru: selectedProduct.descriptionRU,
                };


                const descriptionToDisplay = descriptions[selectedLanguage] || selectedProduct.description;

                return (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal" onClick={(e) => e.stopPropagation()}>
                            <span className="close" onClick={closeModal}>&times;</span>
                            <div className="modal-image">
                                <img src={selectedProduct.imageUrl} alt={selectedProduct.title} />
                            </div>
                            <div className="modal-title">{selectedProduct.title}</div>
                            <div className="modal-description">{descriptionToDisplay}</div>
                            <div className="price_basket">
                                <div className="modal-price">{selectedProduct.price} ლ</div>
                            </div>
                        </div>
                    </div>
                );
            })()}
        </div>




    );
}

export default EditAdd;