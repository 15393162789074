import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { logEvent } from 'firebase/analytics';

import './css/index.css';
import { analytics } from './firebase';

import Main from "./pages/main";
import Userpage from "./pages/userpage";
import Home from "./pages/home";
import Add from "./pages/add";
import Buy from "./pages/buy";
import Kinotel from "./pages/kinotel";
import ORANGE from "./pages/orange";
import CRMA from "./pages/crm";
import Menucrm from "./pages/menucrm";
import EditAdd from "./pages/editadd";
import Global from "./pages/global";
import ProtectedRoute from "./pages/ProtectedRoute";
import LoyaltyCrm from "./pages/LoyaltyCrmInterface/LoyaltyCrm";
import DeliveryCrmInterface from "./pages/DeliveryInterface/DeliveryCrmInterface";


const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem("activeItem")) {
            localStorage.setItem("activeItem", "2");
        }
        logEvent(analytics, 'page_view');
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                localStorage.setItem("LOGstatus", "true");
                localStorage.setItem("USER_NAME", user.displayName || "");
                localStorage.setItem("USER_LOGIN", user.email || "");
                setIsAuthenticated(true);
            } else {
                localStorage.setItem("LOGstatus", "false");
                setIsAuthenticated(false);
            }
            setLoading(false); // Завершаем загрузку после проверки
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Загрузка...</div>; // Можно заменить на красивый спиннер
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/edit-add/:companyId/:productId" element={<EditAdd />} />
                <Route path="/:company" element={<Main />} />
                <Route path="/fastDeliveryCRM" element={<ProtectedRoute element={<Menucrm />} />} />
                <Route path="/g" element={<Global />} />
                <Route path="/loyalty-crm" element={<ProtectedRoute element={<LoyaltyCrm />} />} />
                <Route path="/delivery-crm" element={<ProtectedRoute element={<DeliveryCrmInterface />} />} />
                <Route path="/userpage" element={<Userpage />} />
                <Route path="/kinotel" element={<Kinotel />} />
                <Route path="/orange" element={<ORANGE />} />
                <Route path="/crm" element={<CRMA />} />
                <Route path="/home" element={<Home />} />
                <Route path="/addd" element={<Add />} />
                <Route path="/buy" element={<Buy />} />
            </Routes>
        </Router>
    );
};

createRoot(document.getElementById('root')).render(<App />);