import React, { useEffect, useState } from 'react';
import { fs } from '../../firebase';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';


const DeliveryCrmInterface = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    const user = auth.currentUser;

    // Функция для получения заказов
    const fetchOrders = async () => {
        try {
            setLoading(true);
            const ordersRef = collection(fs, "DeliveryOrder");
            const q = query(ordersRef, where("status", "in", ["pending", "processing", "completed"]));
            const querySnapshot = await getDocs(q);
            const fetchedOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), selectedStatus: doc.data().status }));
            setOrders(fetchedOrders);
        } catch (error) {
            console.error("Ошибка при загрузке заказов:", error);
        } finally {
            setLoading(false);
        }
    };

    // Функция для обновления статуса заказа
    const handleStatusChange = async (orderId, newStatus) => {
        try {
            const orderRef = doc(fs, "DeliveryOrder", orderId);
            await updateDoc(orderRef, { status: newStatus });
            setOrders(prevOrders => prevOrders.map(order => order.id === orderId ? { ...order, status: newStatus } : order));
            alert('Статус заказа обновлён');
        } catch (error) {
            console.error("Ошибка при изменении статуса:", error);
            alert('Ошибка при изменении статуса');
        }
    };

    useEffect(() => {
        if (user) {
            fetchOrders();
        } else {
            alert('Необходимо войти в аккаунт');
        }
    }, [user]);

    if (loading) {
        return <div className='ordercrm-loading'>Загрузка заказов...</div>;
    }

    return (
        <div className='ordercrm-container'>
            <h1 className='ordercrm-title'>Админский кабинет: Управление заказами</h1>
            <table className='ordercrm-table'>
                <thead>
                <tr>
                    <th>Номер заказа</th>
                    <th>Способ получения заказа</th>
                    <th>Имя получателя</th>
                    <th>Телефон</th>
                    <th>Метод оплаты</th>
                    <th>Цена</th>
                    <th>Кол-во персон</th>
                    <th>Время доставки</th>
                    <th>Комментарий</th>
                    <th>Адрес доставки</th>
                    <th>Подъезд</th>
                    <th>Этаж</th>
                    <th>Продукты</th>
                    <th>Статус</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {orders.map((order) => (
                    <tr key={order.id} className='ordercrm-row'>
                        <td>{order.id}</td>
                        <td>{order.orderReceipt}</td>
                        <td>{order.recipientName}</td>
                        <td>{order.phoneNumber}</td>
                        <td>{order.paymentMethod}</td>
                        <td>{order.totalPrice}</td>
                        <td>{order.personsCount}</td>
                        <td>{order.deliveryTime}</td>
                        <td>{order.comment}</td>
                        <td>{order.destination.address}</td>
                        <td>{order.entrance}</td>
                        <td>{order.floor}</td>
                        <td>
                            {order.products.map((product, index) => (
                                <div key={index} className='ordercrm-product'>
                                    {product.productId} - {product.name} - {product.quantity} шт. - {product.price}₾

                                </div>
                            ))}
                        </td>
                        <td>
                            <select
                                value={order.selectedStatus}
                                onChange={(e) => {
                                    const newStatus = e.target.value;
                                    setOrders(prevOrders => prevOrders.map(o => o.id === order.id ? { ...o, selectedStatus: newStatus } : o));
                                }}
                                className='ordercrm-select'
                            >
                                <option value="pending">Ожидает</option>
                                <option value="processing">Обрабатывается</option>
                                <option value="delivering">В пути</option>
                                <option value="declined">Отклонен</option>
                                <option value="completed">Завершён</option>
                            </select>
                        </td>
                        <td>
                            <button
                                onClick={() => handleStatusChange(order.id, order.selectedStatus)}
                                className='ordercrm-button'
                            >
                                Обновить статус
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DeliveryCrmInterface;