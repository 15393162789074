import React, {useState} from "react";
import { getFirestore, collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../css/index.css';
import '../css/add.css';
import {Link} from "react-router-dom";
const dishTypes = {
    Aromiitaliani: [
        "Breakfast", "Antipasti", "Zuppa and Insalatta", "Primi Piatti",
        "Pizza", "Secondi Piatti", "Dolce, Gelato and Bakery", "Bar"
    ],
    Copacabana: ["Cozinha", "Sushi Bar", "Bar"],
    Symposium: ["Kitchen", "Wine", "Alcohol", "Soft Drinks"],
    Poshnosh: ["Sandwiches and Wraps", "Tex Mex", "Boss Burger", "Shawarma", "Bakery", "Sides", "Sauces and Dips", "Drinks"],
    Nadimi: ["Cold dishes", "Hot Dishes", "Kakhetian BBQ", "Khinkali", "Garnish", "Bakery", "Beverages"],
    Cave: ["Menu", "Events"]
};
function Add() {
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');

    const [description, setDescription] = useState('');
    const [descriptionGE, setDescriptionGE] = useState('');
    const [descriptionIT, setDescriptionIT] = useState('');
    const [descriptionAR, setDescriptionAR] = useState('');
    const [descriptionTR, setDescriptionTR] = useState('');
    const [descriptionRU, setDescriptionRU] = useState('');

    const [isPopular, setIsPopular] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [type, setType] = useState('');
    const [sub, setSub] = useState('');

    const db = getFirestore();
    const storage = getStorage();
    const handleAddProduct = async () => {
        if (!company || !type || !title || !price || !selectedFile) {
            alert("Please fill in all required fields and upload an image.");
            return;
        }

        const randomId = Math.floor(100000 + Math.random() * 900000).toString();

        const productData = {
            title,
            type,
            description,
            descriptionGE,
            descriptionIT,
            descriptionAR,
            descriptionTR,
            descriptionRU,
            price,
            sub,
            imageUrl: '',
            id: randomId,
            popular: isPopular,
        };

        try {
            // Добавляем продукт в Firestore
            const productRef = doc(db, company, randomId);  // Используем doc() для создания документа
            await setDoc(productRef, productData);  // Используем setDoc() для добавления документа

            // Загружаем изображение в Firebase Storage
            const storageRef = ref(storage, `IMG/${randomId}`);
            await uploadBytes(storageRef, selectedFile);

            // Получаем URL изображения
            const fileUrl = await getDownloadURL(storageRef);

            // Обновляем документ в Firestore с URL изображения
            await updateDoc(productRef, { imageUrl: fileUrl });

            alert("Product added successfully!");
        } catch (error) {
            console.error("Error adding product: ", error);
            alert("Error adding product. Please try again.");
        }
    };


    return (
        <div className="main p10">
            <Link to={"/fastDeliveryCRM"} className={"products_back_button"}>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                     viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                     strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M15 6l-6 6l6 6"/>
                </svg>
                {('Назад')}
            </Link>
            <div className={"group"}>

                <div className={"group_descr"}>Company</div>
                <select onChange={(e) => setCompany(e.target.value)}>
                    <option value="" disabled selected>{"Company"}</option>
                    <option value="Nadimi">Nadimi</option>
                    <option value="Copacabana">Copacabana</option>
                    <option value="Aromiitaliani">Aromiitaliani</option>
                    <option value="Poshnosh">Poshnosh</option>
                    <option value="Symposium">Symposium</option>
                    <option value="Cave">Cave</option>
                </select>


                <div className={"group_descr"}>Type of dish</div>
                <select onChange={(e) => setType(e.target.value)} disabled={!company}>
                    <option value="" disabled selected>Type of dish</option>
                    {company && dishTypes[company].map((dish) => (
                        <option key={dish} value={dish}>{dish}</option>
                    ))}
                </select>
                <div className={"group_descr"}>Sub Category</div>
                <input placeholder={"Subcategory"} onChange={(e) => setSub(e.target.value)}></input>
                <div className={"group_descr"}>Title</div>
                <input placeholder={"Title"} onChange={(e) => setTitle(e.target.value)}></input>


                <div>
                    <div className="group_descr">Description EN</div>
                    <input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description GE</div>
                    <input
                        value={descriptionGE}
                        onChange={(e) => setDescriptionGE(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description IT</div>
                    <input
                        value={descriptionIT}
                        onChange={(e) => setDescriptionIT(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description AR</div>
                    <input
                        value={descriptionAR}
                        onChange={(e) => setDescriptionAR(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description TR</div>
                    <input
                        value={descriptionTR}
                        onChange={(e) => setDescriptionTR(e.target.value)}
                        placeholder="Ingredients"
                    />

                    <div className="group_descr">Description RU</div>
                    <input
                        value={descriptionRU}
                        onChange={(e) => setDescriptionRU(e.target.value)}
                        placeholder="Ingredients"
                    />

                </div>


                <div className={"group_descr"}>Price</div>
                <input placeholder={"Price"} onChange={(e) => setPrice(e.target.value)}></input>
                <div className={"group_descr"}>Images</div>
                <input
                    autoComplete={"off"}
                    type={"file"}
                    id={"file"}
                    className={"IMG_input"}

                    onChange={(e) => setSelectedFile(e.target.files[0])} // Only one file is selected
                />
                <div className={"popular"}>
                    Popular
                    <input
                        type="checkbox"
                        checked={isPopular}
                        onChange={(e) => setIsPopular(e.target.checked)}
                    />

                </div>
                <button className={"Add_btn"} id={"Add_btn"} onClick={handleAddProduct}>
                    Add a product
                </button>
            </div>
        </div>
    );
}

export default Add;