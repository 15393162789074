import React, { useState } from "react";
import PostOrder from "./PostOrder";
import FirebaseOrder from "./FirebaseOrder";

const AddDelivery = ({ totalPrice, selectedProducts, productQuantities }) => {
    const [pickup, setPickup] = useState({ lat: 55.751244, lng: 37.618423, address: "" });
    const [destination, setDestination] = useState({ lat: 55.751244, lng: 37.618423, address: "" });
    const [recipientName, setRecipientName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [floor, setFloor] = useState("");
    const [entrance, setEntrance] = useState("");
    const [apartment, setApartment] = useState("");
    const orderReceipt = 'delivery'

    // Handler for the destination address input
    const handleDestinationChange = (e) => {
        setDestination({ ...destination, address: e.target.value });
    };
    const [deliveryTimeOption, setDeliveryTimeOption] = useState('asap');
    const [deliveryDateTime, setDeliveryDateTime] = useState('');

    const handleOptionChange = (e) => {
        setDeliveryTimeOption(e.target.value);
    };

    const handleDateTimeChange = (e) => {
        setDeliveryDateTime(e.target.value);
    };
    const [personsCount, setPersonsCount] = useState(1);
    const [comment, setComment] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [changeFrom, setChangeFrom] = useState('');

    const handlePersonsChange = (delta) => {
        setPersonsCount(prev => Math.max(1, prev + delta));
    };

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };
    console.log(totalPrice)
    return (
        <div className="add-delivery-container">

            <h2 className="title">Add Delivery</h2>

            <input
                type="text"
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
                placeholder={'Recipient Name'}
                className="delivery-input"
            />
            <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={'Phone Number'}
                className="delivery-input"
            />

            <input
                type="text"
                value={destination.address}
                onChange={handleDestinationChange}
                placeholder="Delivery Address"
                className="delivery-input"
            />

            <div className="address-input-container">
                <div className="input-group">
                    <label htmlFor="floor">Floor</label>
                    <input
                        type="text"
                        id="floor"
                        value={floor}
                        onChange={(e) => setFloor(e.target.value)}
                        placeholder=""
                        className="delivery-input"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="entrance">Entrance</label>
                    <input
                        type="text"
                        id="entrance"
                        value={entrance}
                        onChange={(e) => setEntrance(e.target.value)}
                        placeholder=""
                        className="delivery-input"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="apartment">Apartment</label>
                    <input
                        type="text"
                        id="apartment"
                        value={apartment}
                        onChange={(e) => setApartment(e.target.value)}
                        placeholder=""
                        className="delivery-input"
                    />
                </div>
            </div>
            <div className={'address-input-container'}>


                <div className="delivery-time-options">
                    <label className="switch-option">
                        <input
                            type="radio"
                            name="deliveryTime"
                            value="asap"
                            checked={deliveryTimeOption === 'asap'}
                            onChange={handleOptionChange}
                            className="switch-input"
                        />
                        <span className="switch-slider"></span>
                        <span className="switch-text">As Soon As Possible</span>
                    </label>

                    <label className="switch-option">
                        <input
                            type="radio"
                            name="deliveryTime"
                            value="scheduled"
                            checked={deliveryTimeOption === 'scheduled'}
                            onChange={handleOptionChange}
                            className="switch-input"
                        />
                        <span className="switch-slider"></span>
                        <span className="switch-text">At Scheduled Time</span>
                    </label>
                    {deliveryTimeOption === 'scheduled' && (
                        <div className="datetime-container" style={{marginTop: '15px'}}>
                            <input
                                type="datetime-local"
                                value={deliveryDateTime}
                                onChange={handleDateTimeChange}
                                className="datetime-input"
                                min={new Date().toISOString().slice(0, 16)}
                            />
                        </div>
                    )}
                </div>


            </div>

            <div className="last-delivery-block">
                {/* Number of persons block */}
                <div className="persons-count-container">
                    <div className="DeliveryMethod">Number of persons</div>
                    <div className="counter-control">
                        <button
                            className="counter-btn minus"
                            onClick={() => handlePersonsChange(-1)}
                        >-
                        </button>
                        <span className="counter-value">{personsCount}</span>
                        <button
                            className="counter-btn plus"
                            onClick={() => handlePersonsChange(1)}
                        >+
                        </button>
                    </div>
                </div>

                {/* Comment block */}
                <div className="comment-container">
                    <div className="DeliveryMethod">Comment for the restaurant</div>
                    <textarea
                        className="comment-textarea"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Your wishes..."
                    />
                </div>

                {/* Payment method block */}
                <div className="payment-container">
                    <div className="DeliveryMethod">Payment Method</div>
                    <select
                        className="payment-select"
                        value={paymentMethod}
                        onChange={handlePaymentChange}
                    >
                        <option value="cash">Cash</option>
                        <option value="card">Card</option>
                        <option value="online">Online</option>
                    </select>

                    {paymentMethod === 'cash' && (
                        <div className="change-input-container">
                            <input
                                type="number"
                                className="change-input"
                                value={changeFrom}
                                onChange={(e) => setChangeFrom(e.target.value)}
                                placeholder="Change from"
                                min="0"
                            />
                        </div>
                    )}
                </div>
            </div>
            <FirebaseOrder
                orderData={{
                    recipientName,
                    phoneNumber,
                    destination,
                    floor,
                    entrance,
                    apartment,
                    deliveryTime: deliveryTimeOption === 'asap' ? 'Now' : deliveryDateTime,
                    personsCount,
                    comment,
                    paymentMethod,
                    changeFrom: paymentMethod === 'cash' ? changeFrom : null,
                    pickupLocation: pickup
                }}
                selectedProducts={selectedProducts}
                productQuantities={productQuantities}
                totalPrice={totalPrice}
                orderReceipt = {orderReceipt}
            />

        </div>

    );
};

export default AddDelivery;