import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { doc, updateDoc,deleteDoc,writeBatch  } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import '../css/index.css';
import '../css/productcrm.css';
import { fs } from '../firebase';
import { Link } from "react-router-dom";
import Bar from "../elements/bar";
import LoyaltyCrm from "./LoyaltyCrmInterface/LoyaltyCrm";
import AddDescription from "./addDescription";
const companies = ["Copacabana", "Aromiitaliani", "Symposium", "Nadimi", "Poshnosh", "Cave"];
const subcategoryOrder = [
    "Cold Dishes", "Hot Dishes", "Salads", "Kakhetian BBQ", "Khinkali", "Garnish", "Bakery",
    "Soft Drink", "Beer", "White wine", "Red wine", "Chacha", "Bar", "Drinks", "Astoria",
    "Bira", "White wine", "Red wine", "Astoria", "Coctails", "Small Plates",
    "Chef's Inspirations", "Dolces", "Soft Drink", "Coffee", "Coctails", "Bear", "White wine",
    "Red wine", "White dry", "White Semi-Dry", "White Semi-Sweet", "Red Dry", "Red Semi-Dry",
    "Red Semi-Sweet", "Prosecco", "Others", "Events", "Menu"
];

const groupBySubcategory = (products) => {
    return products.reduce((groupedProducts, product) => {
        const subcategory = product.sub || "Others";
        if (!groupedProducts[subcategory]) {
            groupedProducts[subcategory] = [];
        }
        groupedProducts[subcategory].push(product);
        return groupedProducts;
    }, {});
};

function MenuCrm({ handleLogout }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(companies[0]);
    const [selectedSubcategory, setSelectedSubcategory] = useState('All');
    const [sortPrice, setSortPrice] = useState('none');
    const [editingSubcategory, setEditingSubcategory] = useState(null);
    const [newSubcategoryName, setNewSubcategoryName] = useState("");
    const [openSubcategories, setOpenSubcategories] = useState({});
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const [productStatuses, setProductStatuses] = useState({});
    const navigate = useNavigate();
    const fetchProducts = async () => {
        try {
            const productList = [];
            const statusMap = {};

            // 📌 Создаем ссылку на коллекцию
            const querySnapshot = await getDocs(collection(fs, selectedCompany));

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                productList.push({ ...data, id: doc.id, company: selectedCompany });
                statusMap[doc.id] = data.status || "on"; // По умолчанию "on"
            });

            setProducts(productList);
            setProductStatuses(statusMap);
        } catch (error) {
            console.error("Ошибка загрузки данных: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [selectedCompany]);
    const openDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

// Функция для закрытия окна
    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };
    const toggleProductStatus = async (productId) => {
        const newStatus = productStatuses[productId] === "off" ? "on" : "off";
        try {
            await updateDoc(doc(fs, selectedCompany, productId), { status: newStatus });

            setProductStatuses((prevStatuses) => ({
                ...prevStatuses,
                [productId]: newStatus
            }));
        } catch (error) {
            console.error("Ошибка обновления статуса:", error);
        }
    };
    const toggleSubcategory = (subcategory) => {
        setOpenSubcategories(prevState => ({
            ...prevState,
            [subcategory]: !prevState[subcategory]
        }));
    };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSubcategoryChange = async () => {
        if (!newSubcategoryName.trim()) return;

        try {
            const batch = writeBatch(fs); // ✅ Используем writeBatch(fs) вместо fs.batch()
            const productsToUpdate = products.filter(product => product.sub === editingSubcategory);

            productsToUpdate.forEach(product => {
                const productRef = doc(fs, selectedCompany, product.id); // ✅ Используем doc()
                batch.update(productRef, { sub: newSubcategoryName });
            });

            await batch.commit(); // ✅ Firestore v9

            setProducts(prev =>
                prev.map(product =>
                    product.sub === editingSubcategory ? { ...product, sub: newSubcategoryName } : product
                )
            );

            setEditingSubcategory(null);
        } catch (error) {
            console.error("Ошибка обновления подкатегории:", error);
        }
    };

    const handleSortPriceChange = (event) => {
        setSortPrice(event.target.value);
    };

    const filteredProducts = products.filter(product => {
        const matchesSearch = product.title.toLowerCase().includes(searchText.toLowerCase()) ||
            product.id.toLowerCase().includes(searchText.toLowerCase());
        const matchesSubcategory = selectedSubcategory === 'All' || product.sub === selectedSubcategory;
        return matchesSearch && matchesSubcategory;
    });

    // Сортировка продуктов по цене
    const sortedProducts = filteredProducts.sort((a, b) => {
        const priceA = parseFloat(a.price);
        const priceB = parseFloat(b.price);

        if (isNaN(priceA) || isNaN(priceB)) return 0; // Если цена не является числом, не сортировать

        if (sortPrice === 'asc') {
            return priceA - priceB;
        } else if (sortPrice === 'desc') {
            return priceB - priceA;
        } else {
            return 0; // Без сортировки
        }
    });

    const groupedProducts = groupBySubcategory(sortedProducts);

    if (loading) {
        return <div className={"loading"}>Loading...</div>;
    }

    const handleDelete = async (productId) => {
        if (window.confirm("Вы действительно хотите удалить объект?")) {
            try {
                await deleteDoc(doc(fs, selectedCompany, productId));
                setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
            } catch (error) {
                console.error("Ошибка удаления: ", error);
            }
        }
    };
    const activeSubcategories = [...new Set(products.map(product => product.sub || "Others"))];

    const handleEditClick = (subcategory) => {
        setEditingSubcategory(subcategory);
        setNewSubcategoryName(subcategory);
    };
    const handleSubcategorySelect = (subcategory) => {
        setSelectedSubcategory(subcategory);
    };
    const handleOpenLoyaltyCrm = () => {
        navigate('/loyalty-crm');
    };
    const handleOpenDeliveryCrm = () => {
        navigate('/delivery-crm');
    };
    return (
        <>
            <Bar/>
            <div className="crm_container">
                <button onClick={handleLogout} className="crm-logout-button">⬅️ Выйти из CRM</button>
                <button className="coin-system" onClick={handleOpenLoyaltyCrm}>Coins++</button>
                <button className="coin-system" onClick={handleOpenDeliveryCrm}>Delivery</button>
                <div className="crm-container">
                    <div className="crm-header">
                        <span>CRM FAST DELIVERY</span>
                    </div>
                    <div className="crm-version">version 1.14</div>
                </div>

                <div className="company-tabs">
                    {companies.map(company => (
                        <button key={company} className={`tab-button ${selectedCompany === company ? 'active' : ''}`}
                                onClick={() => setSelectedCompany(company)}>
                            {company}
                        </button>
                    ))}
                </div>

                {/* Subcategory Filter */}
                <div className="subcategory-tabs">
                    <button
                        className={`subcategory-button ${selectedSubcategory === 'All' ? 'active' : ''}`}
                        onClick={() => handleSubcategorySelect('All')}
                    >
                        All
                    </button>
                    {activeSubcategories.map(subcategory => (
                        <button
                            key={subcategory}
                            className={`subcategory-button ${selectedSubcategory === subcategory ? 'active' : ''}`}
                            onClick={() => handleSubcategorySelect(subcategory)}
                        >
                            {subcategory}
                        </button>
                    ))}
                </div>

                <div className="dopFilterSearch">
                    <input
                        type="text"
                        value={searchText}
                        onChange={handleSearchInputChange}
                        placeholder="Search"
                        className="search-inputs"
                    />
                    <Link to={"/addd"} className="add_item">
                        Add Product +
                    </Link>
                </div>

                {/* Price Sort Filter */}


                <div className="menu-crm">
                    {Object.entries(groupBySubcategory(sortedProducts)).sort(([a], [b]) => {
                        const indexA = subcategoryOrder.indexOf(a);
                        const indexB = subcategoryOrder.indexOf(b);

                        if (indexA === -1 && indexB === -1) return a.localeCompare(b);
                        if (indexA === -1) return 1;
                        if (indexB === -1) return -1;

                        return indexA - indexB;
                    }).map(([subcategory, products]) => (
                        <div className="subcategory" key={subcategory}>
                            <div className="subcategory-header" onClick={() => toggleSubcategory(subcategory)}>
                                {editingSubcategory === subcategory ? (
                                    <input
                                        type="text"
                                        className="subcategory-input"
                                        value={newSubcategoryName}
                                        onChange={(e) => setNewSubcategoryName(e.target.value)}
                                        onBlur={handleSubcategoryChange}
                                        onKeyDown={(e) => e.key === "Enter" && handleSubcategoryChange()}
                                        autoFocus
                                    />
                                ) : (
                                    <>
                                        <h3 className="subcategory-title">{subcategory}</h3>
                                        <button className="edit-category-btn"
                                                onClick={() => handleEditClick(subcategory)}>✏️
                                        </button>
                                    </>
                                )}
                            </div>
                            {openSubcategories[subcategory] && (
                                <div className="product-list">
                                    {products.map(product => (
                                        <div className="product_crm" key={product.id}>
                                            <img className="product_crm_card_img" src={product.imageUrl}
                                                 alt={product.title}/>
                                            <div className="product_crm_card">
                                                <div className="product_crm_id">
                                                    {product.id} | {product.company}
                                                </div>
                                                <div className="product_crm_title">{product.title}</div>
                                                <div className="product_crm_description">{product.description}</div>
                                                <div className="product_crm_price">{product.price}₾</div>
                                            </div>
                                            <div className="crm_icons">
                                                <Link className="crm_icon"
                                                      to={`/edit-add/${product.company}/${product.id}`}>
                                                    ✏️
                                                </Link>
                                                <div className="crm_icon" onClick={() => handleDelete(product.id)}>
                                                    🗑️
                                                </div>
                                                <div className="crm_icon"
                                                     onClick={() => toggleProductStatus(product.id)}>
                                                    {productStatuses[product.id] || "on"}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className='Description-change'>
                    <button onClick={openDescriptionModal}>Change description</button>
                </div>

                <AddDescription isOpen={isDescriptionModalOpen} onClose={closeDescriptionModal}/>

            </div>
        </>
    );
}

export default MenuCrm;