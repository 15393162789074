import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore"; // Изменения тут
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// Firebase config
const firebaseConfig = {
    apiKey: "AIzaSyCGEd76JsIeoNjefh8eseDE-m4fdMIwNJM",
    authDomain: "europe-fa766.firebaseapp.com",
    projectId: "europe-fa766",
    databaseURL: "https://europe-fa766-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "europe-fa766.appspot.com",
    messagingSenderId: "328752709740",
    appId: "1:328752709740:web:27730db1544cf5e9909849",
    measurementId: "G-2C0Y649GQX",
};

// Инициализация Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const authentication = getAuth(app);
const provider = new GoogleAuthProvider();
const fs = getFirestore(app);
const storage = getStorage(app);
const db = getDatabase(app);
// Экспорт сервисов
export { app, analytics, authentication, provider, fs, storage, signInWithPopup,db };