import React, { useState } from 'react';
import Bascket from "./DeliveryInterface/Bascket";
import OrderHistory from "./DeliveryInterface/OrderHistory";

const Delivery = () => {
    const [activeTab, setActiveTab] = useState("bascket");

    return (
        <div>
            <div className="delivery-container">
                <button
                    onClick={() => setActiveTab("bascket")}
                    className={activeTab === "bascket" ? "active" : ""}
                    style={activeTab === "bascket" ? { backgroundColor: "white", color: "black" } : {}}>
                    Delivery
                </button>
                <button
                    onClick={() => setActiveTab("orderHistory")}
                    className={activeTab === "orderHistory" ? "active" : ""}
                    style={activeTab === "orderHistory" ? { backgroundColor: "white", color: "black" } : {}}>
                    Order History
                </button>
            </div>

            {activeTab === "bascket" && <Bascket />}
            {activeTab === "orderHistory" && <OrderHistory />}
        </div>
    );
};

export default Delivery;