import React, { useState } from 'react';
import AddTable from "./AddTable";
import AddSelfTake from "./AddSelfTake";
import AddDelivery from "./AddDelivery";

const DeliveryWay = ({ totalPrice, selectedProducts, productQuantities, onBack }) => {
    const [deliveryMethod, setDeliveryMethod] = useState('Delivery');

    const handleDeliveryChange = (e) => {
        setDeliveryMethod(e.target.value);
    };
    console.log(totalPrice)

    const renderDeliveryComponent = () => {
        switch (deliveryMethod) {
            case 'Delivery':
                return <AddDelivery
                    totalPrice={totalPrice}
                    selectedProducts={selectedProducts}
                    productQuantities={productQuantities}
                />;
            case 'Self Pickup':
                return <AddSelfTake
                    totalPrice={totalPrice}
                    selectedProducts={selectedProducts}
                    productQuantities={productQuantities}
                />;
            case 'At the Table':
                return <AddTable
                    totalPrice={totalPrice}
                    selectedProducts={selectedProducts}
                    productQuantities={productQuantities}
                />;
            default:
                return null;
        }
    };

    return (
        <div className={'DeliveryWay-container'}>
            <button onClick={onBack} className="back-button">← </button>

            <div className={'DeliveryMethod'}>Choose delivery method</div>

            <select
                value={deliveryMethod}
                onChange={handleDeliveryChange}
                className="delivery-select"
            >
                <option value="Delivery">Delivery</option>
                <option value="Self Pickup">Self Pickup</option>
                <option value="At the Table">At the Table</option>
            </select>

            {renderDeliveryComponent()}
        </div>
    );
};

export default DeliveryWay;