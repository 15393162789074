import React, { useState, useEffect } from "react";
import { ref, get } from "firebase/database";
import { db } from "../firebase"; // ✅ Импортируем БД
import "../css/ProtectedRoute.css";
import aroma from "../img/ratatui.gif";

const ProtectedRoute = ({ element }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [correctLogin, setCorrectLogin] = useState(null);
  const [correctPassword, setCorrectPassword] = useState(null);

  useEffect(() => {
    const isAuthenticatedInLocalStorage = localStorage.getItem("isAuthenticated");
    if (isAuthenticatedInLocalStorage === "true") {
      setIsAuthenticated(true);
    }

    const fetchCredentials = async () => {
      try {
        const loginSnapshot = await get(ref(db, "settings/login"));
        const passwordSnapshot = await get(ref(db, "settings/password"));

        if (loginSnapshot.exists() && passwordSnapshot.exists()) {
          setCorrectLogin(String(loginSnapshot.val()).trim()); // Убираем лишние пробелы
          setCorrectPassword(String(passwordSnapshot.val()).trim());

        } else {
          console.error("Логин или пароль отсутствуют в базе");
        }
      } catch (error) {
        console.error("Ошибка доступа к Firebase:", error);
      }
    };

    fetchCredentials();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();


    if (correctLogin === null || correctPassword === null) {
      alert("Ошибка загрузки данных. Попробуйте позже.");
      return;
    }

    if (login.trim() === correctLogin && password.trim() === correctPassword) {
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true");
    } else {
      alert("Неверный логин или пароль");
    }
  };

  if (isAuthenticated) {
    return <div>{React.cloneElement(element)}</div>;
  }

  return (
      <div className="password-container">
        <h1>CRM Fast Delivery</h1>
        <img src={aroma} alt="Loading..." className="gif" />
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Введите логин и пароль</h2>
          <input type="text" value={login} onChange={(e) => setLogin(e.target.value)} placeholder="Введите логин" required />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Введите пароль" required />
          <button type="submit">Войти</button>
        </form>
      </div>
  );
};

export default ProtectedRoute;