import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { app } from "../firebase";

const db = getFirestore(app); // Получаем Firestore

const companies = ["Aromiitaliani", "Copacabana", "Symposium", "Poshnosh", "Nadimi", "Cave"];

const AddDescription = ({ isOpen, onClose }) => {
    const [selectedCompany, setSelectedCompany] = useState(companies[0]);
    const [description, setDescription] = useState("");

    // Функция загрузки описания
    const fetchDescription = async (company) => {
        try {
            const docRef = doc(db, "descriptions", company); // Ссылка на документ
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setDescription(docSnap.data().description || ""); // Загружаем текст
            } else {
                setDescription(""); // Если документа нет, оставляем пустое поле
            }
        } catch (error) {
            console.error("Ошибка при загрузке описания:", error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchDescription(selectedCompany);
        }
    }, [selectedCompany, isOpen]);

    const handleEditDescription = async () => {
        if (!description.trim()) return;
        try {
            const docRef = doc(db, "descriptions", selectedCompany);
            await setDoc(docRef, { description });

            onClose();
        } catch (error) {
            console.error("Ошибка при обновлении описания:", error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Редактировать описание</h2>
                <select
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                >
                    {companies.map(company => (
                        <option key={company} value={company}>{company}</option>
                    ))}
                </select>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Введите описание..."
                />
                <div className="modal-buttons">
                    <button onClick={handleEditDescription}>Редактировать</button>
                    <button onClick={onClose}>Закрыть</button>
                </div>
            </div>
        </div>
    );
};

export default AddDescription;