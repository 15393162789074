import React, { useState } from "react";
import PostOrder from "./PostOrder";
import FirebaseOrder from "./FirebaseOrder";

const AddTable = ({ totalPrice, selectedProducts, productQuantities }) => {
    const [pickup, setPickup] = useState({ lat: 55.751244, lng: 37.618423, address: "" });
    const [destination, setDestination] = useState({ lat: 55.751244, lng: 37.618423, address: "" });
    const [recipientName, setRecipientName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [floor, setFloor] = useState("");
    const [entrance, setEntrance] = useState("");
    const [apartment, setApartment] = useState("");
    const [tableNum, setTableNum] = useState("");
    const orderReceipt = 'table'
    // Handler for the destination address input
    const handleDestinationChange = (e) => {
        setDestination({ ...destination, address: e.target.value });
    };
    const [deliveryTimeOption, setDeliveryTimeOption] = useState('asap');
    const [deliveryDateTime, setDeliveryDateTime] = useState('');

    const handleOptionChange = (e) => {
        setDeliveryTimeOption(e.target.value);
    };

    const handleDateTimeChange = (e) => {
        setDeliveryDateTime(e.target.value);
    };
    const [personsCount, setPersonsCount] = useState(1);
    const [comment, setComment] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [changeFrom, setChangeFrom] = useState('');

    const handlePersonsChange = (delta) => {
        setPersonsCount(prev => Math.max(1, prev + delta));
    };

    const handlePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };
    return (
        <div className="add-delivery-container">

            <h2 className="title">Добавить доставку</h2>

            <input
                type="text"
                value={recipientName}
                onChange={(e) => setRecipientName(e.target.value)}
                placeholder={'Имя получателя'}
                className="delivery-input"
            />
            <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={'Номер телефона'}
                className="delivery-input"
            />
            <input
                type="text"
                value={tableNum}
                onChange={(e) => setTableNum(e.target.value)}
                placeholder={'Номер столика'}
                className="delivery-input"
            />


            <div className={'address-input-container'}>


                <div className="delivery-time-options">
                    <label className="switch-option">
                        <input
                            type="radio"
                            name="deliveryTime"
                            value="asap"
                            checked={deliveryTimeOption === 'asap'}
                            onChange={handleOptionChange}
                            className="switch-input"
                        />
                        <span className="switch-slider"></span>
                        <span className="switch-text">Как можно быстрее</span>
                    </label>

                    <label className="switch-option">
                        <input
                            type="radio"
                            name="deliveryTime"
                            value="scheduled"
                            checked={deliveryTimeOption === 'scheduled'}
                            onChange={handleOptionChange}
                            className="switch-input"
                        />
                        <span className="switch-slider"></span>
                        <span className="switch-text">В указанное время</span>
                    </label>
                </div>

                {deliveryTimeOption === 'scheduled' && (
                    <div className="datetime-container" style={{marginTop: '15px'}}>
                        <input
                            type="datetime-local"
                            value={deliveryDateTime}
                            onChange={handleDateTimeChange}
                            className="datetime-input"
                            min={new Date().toISOString().slice(0, 16)}
                        />
                    </div>
                )}
            </div>

            <div className="last-delivery-block">
                {/* Блок количества персон */}
                <div className="persons-count-container">
                    <div className="Sposob-dostavki">Количество персон</div>
                    <div className="counter-control">
                        <button
                            className="counter-btn minus"
                            onClick={() => handlePersonsChange(-1)}
                        >-
                        </button>
                        <span className="counter-value">{personsCount}</span>
                        <button
                            className="counter-btn plus"
                            onClick={() => handlePersonsChange(1)}
                        >+
                        </button>
                    </div>
                </div>

                {/* Блок комментария */}
                <div className="comment-container">
                    <div className="Sposob-dostavki">Комментарий для заведения</div>
                    <textarea
                        className="comment-textarea"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Ваши пожелания..."
                    />
                </div>

                {/* Блок способа оплаты */}
                <div className="payment-container">
                    <div className="Sposob-dostavki">Способ оплаты</div>
                    <select
                        className="payment-select"
                        value={paymentMethod}
                        onChange={handlePaymentChange}
                    >
                        <option value="cash">Наличными</option>
                        <option value="card">Картой</option>
                        <option value="online">Онлайн</option>
                    </select>


                </div>
            </div>

            <FirebaseOrder
                orderData={{
                    recipientName,
                    phoneNumber,
                    tableNum,
                    deliveryTime: deliveryTimeOption === 'asap' ? 'Now' : deliveryDateTime,
                    personsCount,
                    comment,
                    paymentMethod,
                    ...(paymentMethod === 'cash' && { changeFrom }),
                }}
                orderReceipt={orderReceipt}
                selectedProducts={selectedProducts}
                productQuantities={productQuantities}
                totalPrice={totalPrice}
            />
        </div>
    );
};

export default AddTable;