import React, {useState, useEffect} from "react";
import '../css/index.css';
import '../css/Xbar.css';

import XBar from "../elements/Xbar";
import Userpage from "./userpage";
import Home from "./home";
import Menu from "./menu";
import Map from "./map";
import Kinotel from "./kinotel";
import Bar from "../elements/bar";
import ORANGE from "./orange";
import Delivery from "./delivery";
import LoyaltyPage from "./loyaltyPage";

function Main() {
    const loadFromStorage = key => JSON.parse(localStorage.getItem(key)) || false;

    const [showHome, setShowHome] = useState(loadFromStorage("showHome"));
    const [showServices, setShowServices] = useState(loadFromStorage("showServices"));
    const [showUser, setShowUser] = useState(loadFromStorage("showUser"));
    const [showProducts, setShowProducts] = useState(loadFromStorage("showProducts"));
    const [productPage, setProductPage] = useState(loadFromStorage("productPage"));
    const [showMap, setShowMap] = useState(loadFromStorage("showMap"));
    const [kinotel, setKinotel] = useState(loadFromStorage("showKinotel"));
    const [showLoyaltyPage, setshowLoyaltyPage] = useState(loadFromStorage("showLoyaltyPage"));

    const [activeItem, setActiveItem] = useState(() => {
        const storedItem = localStorage.getItem("activeItem");
        return storedItem !== null ? parseInt(storedItem, 10) : 2;
    });


    const handleXBarItemChange = index => {
        console.log("Переключаемся на:", index);
        setActiveItem(index);
        setShowMap(index === 0);
        setShowServices(index === 3);
        setShowUser(index === 4);
        setShowProducts(index === 1);
        setProductPage(index === 5);
        setKinotel(index === 6);
        setShowHome(index === 2);
        setshowLoyaltyPage(index===7)
        localStorage.setItem("activeItem", index.toString());
    };




    useEffect(() => {
        console.log("showLoyaltyPage:", showLoyaltyPage);
        localStorage.setItem("showHome", JSON.stringify(showHome));
        localStorage.setItem("showServices", JSON.stringify(showServices));
        localStorage.setItem("showUser", JSON.stringify(showUser));
        localStorage.setItem("showKinotel", JSON.stringify(kinotel));
        localStorage.setItem("showProducts", JSON.stringify(showProducts));
        localStorage.setItem("productPage", JSON.stringify(productPage));
        localStorage.setItem("showMap", JSON.stringify(showMap));
        localStorage.setItem("showLoyaltyPage", JSON.stringify(showLoyaltyPage));
    }, [showHome, showServices, showUser, showProducts, productPage, showMap, kinotel,showLoyaltyPage]);


    useEffect(() => {
        handleXBarItemChange(activeItem);
    }, []);


    return (
        <div className="">

            <Bar/>


            {showUser && <Userpage handleXBarItemChange={handleXBarItemChange}/>}
            {showHome && <Home handleXBarItemChange={handleXBarItemChange } />}
            {showLoyaltyPage && <LoyaltyPage handleXBarItemChange={handleXBarItemChange}/>}
            {showMap && <Delivery />}
            {showServices && <Menu handleXBarItemChange={handleXBarItemChange}/>}

            {showProducts && <Map handleXBarItemChange={handleXBarItemChange}/>}
            {productPage && <Kinotel handleXBarItemChange={handleXBarItemChange}/>}
            {kinotel && <ORANGE handleXBarItemChange={handleXBarItemChange}/>}


            <XBar activeItem={activeItem} onItemClick={handleXBarItemChange}/>
        </div>
    );
}

export default Main;